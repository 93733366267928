import * as Yup from 'yup';
import i18n from 'i18next';

export const loginSchema = () => {
    return Yup.object().shape({
        email: Yup.string()
            .email(i18n.t('portalValidation-email'))
            .required(i18n.t('portalValidation-required')),
        password: Yup.string().required(i18n.t('portalValidation-required')),
    });
};

export const initialValues = {
    email: '',
    password: '',
};
