import fontRegular from '../assets/fonts/IntroCond-Regular.ttf';
import fontBold from '../assets/fonts/IntroCond-Bold.ttf';
import * as colors from './colors';

const primaryFont = 'IntroCond';
const defaultFont = 'Roboto, sans-serif';

export const fontFace = {
    regular: {
        fontFamily: primaryFont,
        src: `url(${fontRegular})`,
    },
    bold: {
        fontFamily: primaryFont,
        src: `url(${fontBold})`,
        fontWeight: 'bold',
    },
};

export const hero = {
    _1: {
        fontSize: '7.2rem',
        lineHeight: '9rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
    _2: {
        fontSize: '6.0rem',
        lineHeight: '7.5rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
};

export const heading = {
    _1: {
        fontSize: '4.8rem',
        lineHeight: '6rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
    _2: {
        fontSize: '3.3rem',
        lineHeight: '4.1rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
    _3: {
        fontSize: '2.6rem',
        lineHeight: '3.3rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
    _4: {
        fontSize: '2.2rem',
        lineHeight: '2.64rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
    _5: {
        fontSize: '2rem',
        lineHeight: '2.4rem',
        fontWeight: 'bold',
        fontFamily: primaryFont,
        color: colors.prussianBlue,
    },
};

export const callout = {
    _1: {
        regular: {
            fontFamily: primaryFont,
            fontSize: '1.7rem',
            lineHeight: '2.1rem',
            color: colors.prussianBlue,
        },
        bold: {
            fontFamily: primaryFont,
            fontSize: '1.7rem',
            lineHeight: '2.1rem',
            color: colors.prussianBlue,
            fontWeight: 'bold',
        },
    },
    _2: {
        regular: {
            fontFamily: primaryFont,
            fontSize: '1.4rem',
            lineHeight: '1.5rem',
            color: colors.prussianBlue,
        },
        bold: {
            fontFamily: primaryFont,
            fontSize: '1.4rem',
            lineHeight: '1.5rem',
            fontWeight: 'bold',
        },
    },
};

export const subheadline = {
    regular: {
        fontFamily: primaryFont,
        fontSize: '1.6rem',
        lineHeight: '2.0rem',
        letterSpacing: '0.024rem',
        color: colors.prussianBlue,
    },
    bold: {
        fontFamily: primaryFont,
        fontSize: '1.6rem',
        lineHeight: '2.0rem',
        letterSpacing: '0.025rem',
        color: colors.prussianBlue,
        fontWeight: 'bold',
    },
};

export const body = {
    _1: {
        regular: {
            fontSize: '1.7rem',
            lineHeight: '2.2rem',
            fontWeight: '400',
            fontFamily: defaultFont,
        },
        bold: {
            fontSize: '1.7rem',
            lineHeight: '2.2rem',
            fontWeight: '500',
            fontFamily: defaultFont,
        },
    },
    _2: {
        regular: {
            fontSize: '1.6rem',
            lineHeight: '2.1rem',
            fontWeight: '400',
            fontFamily: defaultFont,
        },
        bold: {
            fontSize: '1.6rem',
            lineHeight: '2.1rem',
            fontWeight: '500',
            fontFamily: defaultFont,
        },
    },
};

export const footnote = {
    regular: {
        fontSize: '1.3rem',
        lineHeight: '1.8rem',
        letterSpacing: '0.025rem',
        fontFamily: defaultFont,
    },
    bold: {
        fontSize: '1.3rem',
        lineHeight: '1.8rem',
        fontWeight: '500',
        fontFamily: defaultFont,
    },
    link: {
        fontFamily: defaultFont,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '18px',
    },
};

export const caption = {
    _1: {
        regular: {
            fontSize: '1.2rem',
            lineHeight: '1.6rem',
            fontWeight: '400',
            letterSpacing: '0.025rem',
            fontFamily: defaultFont,
        },
        bold: {
            fontSize: '1.2rem',
            lineHeight: '1.6rem',
            fontWeight: '500',
            letterSpacing: '0.025rem',
            fontFamily: defaultFont,
        },
    },
    _2: {
        regular: {
            fontSize: '1.1rem',
            lineHeight: '1.3rem',
            fontWeight: '400',
            letterSpacing: '0.025rem',
            fontFamily: defaultFont,
        },
        bold: {
            fontSize: '1.1rem',
            lineHeight: '1.3rem',
            fontWeight: '500',
            letterSpacing: '0.025rem',
            fontFamily: defaultFont,
        },
    },
};

export const button = {
    fontSize: '1.7rem',
    lineHeight: '2.1rem',
    fontWeight: 'bold',
    fontFamily: primaryFont,
};
