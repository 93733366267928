import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { createClient, EntryCollection } from 'contentful';
import { Settings } from 'luxon';
import { locale } from '../helpers/languageHelpers';

const {
    VITE_CONTENTFUL_ACCESS_TOKEN,
    VITE_CONTENTFUL_SPACE_ID,
    VITE_CONTENTFUL_PORTAL_PLATFORM_ID,
    VITE_CONTENTFUL_TABLES_PLATFORM_ID,
} = import.meta.env;

const client = createClient({
    space: VITE_CONTENTFUL_SPACE_ID || '',
    accessToken: VITE_CONTENTFUL_ACCESS_TOKEN || '',
});

void i18n.use(initReactI18next).init();

export const ContentfulContext = createContext({
    client,
    locale: locale().i18,
});

type ContentfulEntry = {
    fields: {
        key: string;
        value: unknown;
    };
};

type ContentfulCollection = EntryCollection<{
    key: string;
    value: unknown;
}>;

// Stops app from rendering until all content is fetched.
const ContentGate: FC<PropsWithChildren> = ({ children }) => {
    const [contentIsFetched, setContentIsFetched] = useState(false);

    const formatContentfulData = (entries: ContentfulCollection) => {
        const formattedData: Record<string, unknown> = {};
        (entries?.includes?.Entry as ContentfulEntry[]).forEach((entry) => {
            const { key, value } = entry.fields;

            if (value) {
                formattedData[key] = value;
            }
        });

        return formattedData;
    };

    useEffect(() => {
        Settings.defaultLocale = locale().i18;
    }, []);

    useEffect(() => {
        const getContent = async () => {
            // Includes Rich Texts (which are not used with i18Next but are instead fetched where they are used)
            // It would be nice to filter out all Rich Texts in this call so not to fetch them twice.
            // I could not get that to work since the Contentful documentation is very vague about how to do it
            // This link seems to be the answer but unclear how to apply it to this case
            // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/search-on-references
            const contentfulEntries = await client.getEntries<{ key: string; value: unknown }>({
                'sys.id': VITE_CONTENTFUL_PORTAL_PLATFORM_ID,
                include: 10,
                limit: 1000,
                locale: locale().i18,
            });

            const contentfulTableEntries = await client.getEntries<{ key: string; value: unknown }>(
                {
                    'sys.id': VITE_CONTENTFUL_TABLES_PLATFORM_ID,
                    include: 10,
                    limit: 1000,
                    locale: locale().i18,
                },
            );

            const formattedData = {
                [locale().i18]: {
                    translation: {
                        ...formatContentfulData(contentfulEntries),
                        ...formatContentfulData(contentfulTableEntries),
                    },
                },
            };

            await i18n
                .addResourceBundle(
                    locale().i18,
                    'translation',
                    formattedData[locale().i18].translation,
                    true,
                    true,
                )
                .changeLanguage(locale().i18, () => {
                    setContentIsFetched(true);
                });
        };

        void getContent();
    }, []);

    if (!contentIsFetched) {
        return null;
    }

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <ContentfulContext.Provider value={{ client, locale: locale().i18 }}>
            {children}
        </ContentfulContext.Provider>
    );
};

export default ContentGate;
