import { createUseStyles } from 'react-jss';
import { baseSpacing } from '../../../foundation/layout';
import { white } from '../../../foundation/colors';

interface StyleProps {
    toggleMenuComponentWidth: number;
    menuArrowWidth: number;
}

const styles = createUseStyles({
    menuContainer: {
        position: 'relative',
        cursor: 'pointer',
        zIndex: 2,
    },
    activeContainer: {
        position: 'absolute',
        filter: 'drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.33))',
        right: 0,
    },
    menuItemsContainer: {
        backgroundColor: white.highEmphasis,
        marginRight: -baseSpacing * 3,
    },
    menuArrow: {
        marginRight: (props: StyleProps) =>
            (props.toggleMenuComponentWidth - props.menuArrowWidth) / 2,
        display: 'flex',
    },
    arrowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export default styles;
