import { FC } from 'react';
import styles from './UserImagePlaceholder.style';
import { getInitials } from '../../../helpers/generalHelpers';

interface UserImagePlaceholderProps {
    userName: string;
}

const UserImagePlaceholder: FC<UserImagePlaceholderProps> = ({ userName }) => {
    const initials = getInitials(userName);
    const classes = styles();
    return (
        <div className={classes.userImagePlaceholder}>
            <p className={classes.initialText}>{initials}</p>
        </div>
    );
};

export default UserImagePlaceholder;
