import axios from 'axios';
import {
    publicImageUrls,
    questionFlowsDuplicateUrl,
    questionFlowsImagesUrl,
    questionFlowsRelationUrl,
    questionFlowsUrl,
    questionOptionUrl,
    questionStepUrl,
    questionUrl,
    vetCategoriesUrl,
} from '../network/endpoints/questionFlows';

export const getQuestionFlows = async () => {
    try {
        const { data, status } = await axios.get<QuestionFlowsResponse[]>(questionFlowsUrl());

        return status !== 204 ? data : null;
    } catch (error: unknown) {
        return null;
    }
};

export const updateQuestionFlow = async (data: Partial<QuestionFlowsResponse>) => {
    const response = await axios.patch<QuestionFlowsResponse>(questionFlowsUrl(), data);
    return response.data;
};

export const postQuestionFlow = async () => {
    const response = await axios.post<QuestionFlowsResponse>(questionFlowsUrl());
    return response.data;
};

export const deleteQuestionFlow = async (id: number) => {
    const response = await axios.delete<QuestionFlowsResponse>(questionFlowsUrl(id));
    return response.data;
};

export const duplicateQuestionFlow = async (id: number) => {
    const response = await axios.post<QuestionFlowsResponse>(questionFlowsDuplicateUrl(id));
    return response.data;
};

export const postQuestion = async (data: Omit<Question, 'id'>) => {
    const response = await axios.post<Question>(questionUrl(), data);
    return response.data;
};

export const patchQuestion = async (data: Question) => {
    const response = await axios.patch<Question>(questionUrl(), data);
    return response.data;
};

export const patchQuestionStep = async (id: number, oldStep: number, newStep: number) => {
    const response = await axios.patch<QuestionFlowsResponse>(questionStepUrl(), {
        questionFlowId: id,
        oldStep,
        newStep,
    });
    return response.data;
};

export const deleteQuestion = async (id: number) => {
    const response = await axios.delete(questionUrl(id));
    return response.data;
};

export const postQuestionOption = async (data: QuestionOption) => {
    const response = await axios.post<QuestionOption>(questionOptionUrl(), data);
    return response.data;
};

export const deleteQuestionOption = async (id: number) => {
    const response = await axios.delete<QuestionOption>(questionOptionUrl(id));
    return response.data;
};

export const getVetCategories = async () => {
    try {
        const { data, status } = await axios.get<QuestionFlowsResponse[]>(vetCategoriesUrl());

        return status !== 204 ? data : null;
    } catch (error: unknown) {
        return null;
    }
};

export const getPublicImageUrls = async () => {
    try {
        const { data, status } = await axios.get<string[]>(publicImageUrls());

        return status !== 204 ? data : null;
    } catch (error: unknown) {
        return null;
    }
};

export const createQuestionRelation = async (data: Relation) => {
    const response = await axios.post<RelationBackend>(questionFlowsRelationUrl(), data);
    return response.data;
};

export const updateQuestionRelation = async (data: Relation) => {
    const response = await axios.patch<RelationBackend>(questionFlowsRelationUrl(), data);
    return response.data;
};

export const deleteQuestionRelation = async (id: number) => {
    const response = await axios.delete<RelationBackend>(questionFlowsRelationUrl(id));
    return response.data;
};

export const uploadQuestionFlowImage = async (name: string, formData: FormData) => {
    const response = await axios.post<string>(questionFlowsImagesUrl(name), formData);
    return response.data;
};
