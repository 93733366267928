import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { UserRoles } from '../../types/enums';

interface RoleGuardProps {
    roles: UserRoles[];
    children: JSX.Element;
}

const RoleGuard: FC<RoleGuardProps> = ({ roles, children }) => {
    const { type: userRole } = useSelector((state: AppState) => state.authentication.user);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{roles.includes(userRole as UserRoles) && children}</>;
};

export default RoleGuard;
