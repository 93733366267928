import { da, enUS, fi, nb, sv } from 'date-fns/locale';

enum Languages {
    sv = 'sv',
    nb = 'nb',
    no = 'no',
    fi = 'fi',
    da = 'da',
    en = 'en-US',
}

const supportedLanguages: Record<Languages, { i18: string; dateFns: Locale }> = {
    [Languages.sv]: { i18: 'sv-SE', dateFns: sv },
    [Languages.nb]: { i18: 'nb-NO', dateFns: nb },
    [Languages.no]: { i18: 'nb-NO', dateFns: nb },
    [Languages.da]: { i18: 'da-DK', dateFns: da },
    [Languages.fi]: { i18: 'fi-FI', dateFns: fi },
    [Languages.en]: { i18: 'en-US', dateFns: enUS },
};

// Removes region-part since it seems to be very inconsistent and
// we are only interested in what language the browser is using
// example: nb-SE -> nb
const browserLanguage = navigator.language.split('-')[0];

export const locale = (): { i18: string; dateFns: Locale } =>
    supportedLanguages[browserLanguage as Languages] || supportedLanguages[Languages.sv];
