import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import LoginPage from '../pages/LoginPage/LoginPage';
import { AppState } from '../store/store';
import { UserRoles } from '../types/enums';

const PublicRoute = () => {
    const { signedIn, user } = useSelector((state: AppState) => state.authentication);

    if (!signedIn) {
        return <LoginPage />;
    }

    if (user.type === UserRoles.ADVISOR) {
        return <Navigate to="/flows" />;
    }

    return <Navigate to="/flows" />;
};

export default PublicRoute;
