// ----------- OLD COLORS, SHOULD BE REMOVED ----------
export const prussianBlue = '#00427A';
export const primary = '#005AA0';
export const secondary = '#AE050F';
export const active = '#009CDE';
export const action = '#E30613';
export const lightBlueGrey = '#CAD5E1';
export const backgroundRed = '#FBD5D6';
// ----------- END ----------

export const stateOverlay = {
    hover: 'rgba(0, 0, 0, 0.08)',
    focused: 'rgba(0, 0, 0, 0.12)',
    pressed: 'rgba(0, 0, 0, 0.16)',
    white: {
        hover: 'rgba(255,255,255, 0.08)',
        focus: 'rgba(255,255,255, 0.12)',
        pressed: 'rgba(255,255,255, 0.32)',
    },
};

export const black = {
    disabled: 'rgba(0,0,0,0.38)',
    mediumEmphasis: 'rgba(0,0,0,0.6)',
    highEmphasis: 'rgba(0,0,0,0.87)',
    inactive: 'rgba(0,0,0,0.54)',
};

export const white = {
    disabled: 'rgba(255,255,255,0.5)',
    mediumEmphasis: 'rgba(255,255,255,0.7)',
    highEmphasis: 'rgba(255,255,255,1)',
    inactive: 'rgba(255,255,255,0.54)',
};

export const blue = {
    hover: 'rgba(0, 66, 122, 0.08)',
    focus: 'rgba(0, 66, 122, 0.12)',
    pressed: 'rgba(0, 66, 122, 0.16)',
    background: '#EBF4F9',
    active: '#009CDE',
    primary: 'rgb(0, 90, 160)',
    prussian: '#00427A',
    marine: '#01325B',
    lightGrey: 'rgba(202,213,225, 1)',
    lightGreyTransparent: 'rgba(202,213,225, 0.24)',
};

export const neutral = {
    white: '#ffffff',
    black: '#000000',
    _50: '#F9FAFA',
    _100: '#F3F5F6',
    _200: '#E3E6E8',
    _300: '#C6C7C8',
    _400: '#AEB0B2',
    _500: '#8E9193',
    _600: '#636566',
    _700: '#3A3B3C',
    _800: '#2C2D2E',
    _900: '#1C1D1E',
};

export const red = {
    _900: '#86131A',
    _700: 'rgb(174, 5, 15)',
    _500: '#E30613',
    _100: '#FBCCD2',
    _50: '#FBD5D6',
    hover: 'rgba(174, 5, 15, 0.08)',
    active: 'rgba(174, 5, 15, 0.12)',
};

export const green = {
    _900: '#225601',
    _700: '#397D0C',
    _500: '#659C41',
    _100: '#D2EBC1',
    _50: '#DBECD0',
};

export const splash = {
    pink: '#E68699',
    yellow: '#F8A91E',
};

export const external = {
    bankId: {
        darkBlue: '#235971',
        lightBlue: '#479CBE',
    },
    klarna: {
        buttonActive: '#DC7693',
        pink: '#FFB3C7',
    },
};
