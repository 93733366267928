import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import i18n from 'i18next';
import { Settings } from 'luxon';
import { getPublicImageUrls } from '../hooks/swr';

// interface RelatedData {
//     vetCategories: any;
// }

export interface QuestionFlowValues {
    question: Question | null;
    setQuestion: (question: Question | null) => void;
    lastQuestion: Question | null;
    setLastQuestion: (question: Question | null) => void;
    translations: string[];
    publicImages: string[];
    questionIds: number[];
    setQuestionIds: (value: number[]) => void;
    questionSteps: number[];
    setQuestionSteps: (value: number[]) => void;
}

const QuestionFlowContext = createContext<QuestionFlowValues | undefined>(undefined);

const QuestionFlowProvider: FC<PropsWithChildren> = ({ children }) => {
    const [translations, setTranslations] = useState<string[]>([]);
    const [question, setQuestion] = useState<Question | null>(null);
    const [lastQuestion, setLastQuestion] = useState<Question | null>(null);
    // const [related, setRelated] = useState<RelatedData | null>(null);
    const [publicImages, setPublicImages] = useState<string[]>([]);
    const [questionIds, setQuestionIds] = useState<number[]>([]);
    const [questionSteps, setQuestionSteps] = useState<number[]>([]);

    useEffect(() => {
        setTimeout(() => {
            if (!translations.length) {
                const foundTranslations = i18n.getResourceBundle(
                    Settings.defaultLocale,
                    'translation',
                );
                if (foundTranslations) {
                    setTranslations(Object.keys(foundTranslations));
                }
            }
        }, 500);
    }, [translations, setTranslations]);

    useEffect(() => {
        const getPublicImages = async () => {
            const imageUrls = await getPublicImageUrls();
            if (imageUrls) {
                setPublicImages(imageUrls);
            }
        };
        void getPublicImages();
    }, []);

    const value = useMemo(
        () => ({
            question,
            setQuestion,
            translations,
            lastQuestion,
            setLastQuestion,
            publicImages,
            questionIds,
            setQuestionIds,
            questionSteps,
            setQuestionSteps,
        }),
        [
            question,
            setQuestion,
            translations,
            lastQuestion,
            setLastQuestion,
            publicImages,
            questionIds,
            setQuestionIds,
            questionSteps,
            setQuestionSteps,
        ],
    );

    return <QuestionFlowContext.Provider value={value}>{children}</QuestionFlowContext.Provider>;
};

export default QuestionFlowProvider;

export const useQuestionFlow = () => {
    const context = useContext(QuestionFlowContext);
    if (context === undefined) {
        throw new Error('useQuestionFlow must be used within a QuestionFlowProvider');
    }
    return context;
};
