import * as typography from './foundation/typography';

export default {
    '@global': {
        '*': {
            margin: '0px',
            padding: '0px',
            boxSizing: 'inherit',
        },
        '*::after': {
            extend: '*',
        },
        '*::before': {
            extend: '*',
        },
        html: {
            boxSizing: 'border-box',
            fontSize: '75%',
        },
    },
    '@font-face': [
        {
            ...typography.fontFace.regular,
        },
        {
            ...typography.fontFace.bold,
        },
    ],
};
