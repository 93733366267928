import axios, { AxiosRequestConfig } from 'axios';
import { useMemo, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

import { refreshTokenUrl } from './endpoints/authentication';
import { refreshAccessToken } from './requests/authentication';

export const AxiosProvider = ({ children }: { children: JSX.Element }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { signOutUser } = useAuth();

    useMemo(() => {
        axios.interceptors.request.use(async (config) => {
            const tempConfig: AxiosRequestConfig = { ...config };

            if (tempConfig.headers) {
                if (!tempConfig.headers['Content-Type']) {
                    tempConfig.headers['Content-Type'] = 'application/json';
                }
                tempConfig.headers['Accept-Language'] = 'se';
            }

            try {
                const token = localStorage.getItem('accessToken');
                if (
                    token &&
                    tempConfig.headers &&
                    !tempConfig.headers.Authorization &&
                    !config.url?.includes(refreshTokenUrl)
                ) {
                    tempConfig.headers.Authorization = `Bearer ${token}`;
                }

                return tempConfig;
            } catch (e) {
                return tempConfig;
            }
        });
    }, []);

    useMemo(() => {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.response && error.response.status === 401 && !isRefreshing) {
                    setIsRefreshing(true);
                    const currentAccessToken = localStorage.getItem('accessToken');

                    const refreshToken = localStorage.getItem('refreshToken');
                    localStorage.setItem('refreshToken', '');
                    if (
                        currentAccessToken &&
                        error.response.config.url !== refreshTokenUrl &&
                        refreshToken
                    ) {
                        try {
                            const tempError = error;

                            const { accessToken } = await refreshAccessToken(refreshToken!);
                            tempError.config.headers.Authorization = `Bearer ${accessToken}`;
                            return await axios.request(tempError.config);
                        } catch (err) {
                            return Promise.reject(error);
                        } finally {
                            setIsRefreshing(false);
                        }
                    } else {
                        if (error.response.config.url === refreshTokenUrl) {
                            signOutUser();
                        }
                        setIsRefreshing(false);
                    }
                }
                return Promise.reject(error);
            },
        );
    }, [isRefreshing, signOutUser]);

    return children;
};
