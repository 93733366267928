import { FC } from 'react';
import {
    TextField,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuestionFlow } from '../../../contexts/QuestionFlowContext';
import { KeyboardType } from '../../../types/enums/question';

interface ContentProps {
    content: QuestionContent | null;
    updateQuestion: (param: keyof Question, value: QuestionValueTypes) => void;
}
const Content: FC<ContentProps> = ({ content, updateQuestion }) => {
    const { translations, publicImages } = useQuestionFlow();
    const updateContent = (value: QuestionContent) => {
        // remove all "empty" values
        const cleanedContent = Object.fromEntries(
            Object.entries(value).filter(([_, param]) => param !== ''),
        );

        updateQuestion('content', Object.keys(cleanedContent).length ? cleanedContent : null);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{`Content${content ? '...' : ''}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ wordBreak: 'break-word' }}>
                    {content ? JSON.stringify(content) : 'Empty'}
                </Typography>
                <br />
                <Autocomplete
                    disablePortal
                    options={translations}
                    value={content?.description || ''}
                    fullWidth
                    onChange={(_, value: string | null) =>
                        updateContent({ ...content, description: value || '' })
                    }
                    onInputChange={(_, value: string) =>
                        updateContent({ ...content, description: value || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Description" />}
                    style={{ marginTop: 6, marginBottom: 6 }}
                    freeSolo
                />
                <Autocomplete
                    disablePortal
                    options={publicImages}
                    value={content?.image || ''}
                    fullWidth
                    onChange={(_, value: string | null) =>
                        updateContent({ ...content, image: value || '' })
                    }
                    onInputChange={(_, value: string) =>
                        updateContent({ ...content, image: value || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Image" />}
                    style={{ marginTop: 12, marginBottom: 12 }}
                    freeSolo
                />
                <TextField
                    select
                    label="ImageSize"
                    variant="outlined"
                    fullWidth
                    value={content?.imageSize || ''}
                    style={{ marginTop: 6, marginBottom: 6 }}
                    onChange={(e) => updateContent({ ...content, imageSize: e.target.value })}
                >
                    <MenuItem key="small" value="small">
                        Small
                    </MenuItem>
                    <MenuItem key="medium" value="medium">
                        Medium
                    </MenuItem>
                    <MenuItem key="large" value="large">
                        Large
                    </MenuItem>
                    <MenuItem key="large" value="">
                        Empty
                    </MenuItem>
                </TextField>
                <Autocomplete
                    disablePortal
                    options={translations}
                    value={content?.inputLabel || ''}
                    fullWidth
                    onChange={(_, value: string | null) =>
                        updateContent({ ...content, inputLabel: value || '' })
                    }
                    onInputChange={(_, value: string) =>
                        updateContent({ ...content, inputLabel: value || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="InputLabel" />}
                    style={{ marginTop: 12, marginBottom: 12 }}
                    freeSolo
                />
                <Autocomplete
                    disablePortal
                    options={translations}
                    value={content?.inputPlaceholder || ''}
                    fullWidth
                    onChange={(_, value: string | null) =>
                        updateContent({ ...content, inputPlaceholder: value || '' })
                    }
                    onInputChange={(_, value: string) =>
                        updateContent({ ...content, inputPlaceholder: value || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="InputPlaceholder" />}
                    style={{ marginTop: 12, marginBottom: 12 }}
                    freeSolo
                />
                <Autocomplete
                    disablePortal
                    options={translations}
                    value={content?.answerSuffix || ''}
                    fullWidth
                    onChange={(_, value: string | null) =>
                        updateContent({ ...content, answerSuffix: value || '' })
                    }
                    onInputChange={(_, value: string) =>
                        updateContent({ ...content, answerSuffix: value || '' })
                    }
                    renderInput={(params) => <TextField {...params} label="AnswerSuffix" />}
                    style={{ marginTop: 12, marginBottom: 6 }}
                    freeSolo
                />
                <TextField
                    label="InputMaxLength"
                    value={content?.inputMaxLength || ''}
                    type="number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                        updateContent({
                            ...content,
                            inputMaxLength: e.target.value === '0' ? '' : Number(e.target.value),
                        })
                    }
                    style={{ marginTop: 6, marginBottom: 6 }}
                />
                <TextField
                    select
                    label="keyboardType"
                    variant="outlined"
                    fullWidth
                    value={content?.keyboardType || ''}
                    style={{ marginTop: 6, marginBottom: 6 }}
                    onChange={(e) => updateContent({ ...content, keyboardType: e.target.value })}
                >
                    {Object.keys(KeyboardType).map((item) => (
                        <MenuItem
                            key={item}
                            value={KeyboardType[item as keyof typeof KeyboardType]}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
                <FormControl style={{ margin: 6 }}>
                    <FormLabel>Optional</FormLabel>
                    <RadioGroup
                        row
                        value={content?.optional || ''}
                        onChange={(e) => {
                            updateContent({ ...content, optional: e.target.value as any });
                        }}
                    >
                        <FormControlLabel value="" control={<Radio />} label="False" />
                        <FormControlLabel value={true as any} control={<Radio />} label="True" />
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};

export default Content;
