import { FC } from 'react';
import styles from './InputHelperBlock.style';

interface InputHelperBlockProps {
    error?: string;
    infoText?: string;
}

const InputHelperBlock: FC<InputHelperBlockProps> = ({ error, infoText }) => {
    const classes = styles();

    return (
        <div className={classes.helperTextContainer}>
            <p className={`${classes.helperText} ${error && classes.errorText}`}>
                {error || infoText}
            </p>
        </div>
    );
};

export default InputHelperBlock;
