import { createUseStyles } from 'react-jss';
import * as colors from '../../foundation/colors';
import * as layout from '../../foundation/layout';

const styles = createUseStyles({
    navBar: {
        background: colors.prussianBlue,
        padding: [[0, layout.baseSpacing * 3, 0, layout.baseSpacing * 2]],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        zIndex: 2,
    },
    button: {
        color: colors.neutral.white,
    },
    navBarPlaceholder: {
        height: (height) => height,
        width: '100%',
    },
    links: {
        display: 'flex',
        '& a:last-of-type': {
            marginRight: layout.baseSpacing * 3,
        },
    },
    regionMenuIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: layout.baseSpacing * 3,
    },
    accountMenuIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default styles;
