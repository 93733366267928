import { FC, useEffect, useRef, useState } from 'react';
import styles from './Menu.style';
import { useOutsideAlerter } from '../../../hooks';
import MenuItem from './MenuItem/MenuItem';
import { ReactComponent as ArrowTop } from '../../../assets/svgs/ArrowTop.svg';

export interface MenuItem {
    onClick: () => unknown;
    image?: string;
    text: string;
}

interface MenuProps {
    toggleMenuComponent: JSX.Element;
    menuItems: MenuItem[];
    testId: string;
}

const Menu: FC<MenuProps> = ({ toggleMenuComponent, menuItems, testId }) => {
    const [isActive, setIsActive] = useState(false);
    const toggleMenuComponentRef = useRef<HTMLDivElement | null>(null);
    const menuArrowRef = useRef<HTMLDivElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [menuArrowWidth, setMenuArrowWidth] = useState<number>(0);
    const [toggleMenuComponentWidth, setToggleMenuComponentWidth] = useState<number>(0);
    const classes = styles({ toggleMenuComponentWidth, menuArrowWidth });
    useOutsideAlerter(menuRef, () => {
        setIsActive(false);
    });

    useEffect(() => {
        setToggleMenuComponentWidth(toggleMenuComponentRef?.current?.offsetWidth || 0);
        if (isActive) {
            setMenuArrowWidth(menuArrowRef.current?.offsetWidth || 0);
        }
    }, [isActive]);

    return (
        <div className={classes.menuContainer} ref={menuRef}>
            <div
                onClick={() => {
                    setIsActive((currentState) => !currentState);
                }}
                onKeyDown={() => setIsActive(true)}
                ref={toggleMenuComponentRef}
                data-testid={testId || 'toggleMenu'}
            >
                {toggleMenuComponent}
            </div>
            {isActive && (
                <div className={classes.activeContainer} onClick={() => setIsActive(false)}>
                    <div className={classes.arrowContainer}>
                        <div className={classes.menuArrow} ref={menuArrowRef}>
                            <ArrowTop />
                        </div>
                    </div>
                    <ul className={classes.menuItemsContainer}>
                        {menuItems.map(({ image, text, onClick, key, testId }) => (
                            <MenuItem
                                image={image}
                                testId={testId}
                                text={text}
                                onClick={onClick}
                                key={key}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Menu;
