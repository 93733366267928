import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserRoles } from '../types/enums';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import QuestionFlowPage from '../pages/QuestionFlowPage/QuestionFlowPage';
// import QuestionRelatedPage from '../pages/QuestionRelatedPage/QuestionFlowPage';

const ApplicationRouter: FC = () => {
    return (
        <Routes>
            <Route path="/" element={<PublicRoute />} />
            <Route
                path="/flows"
                element={
                    <PrivateRoute roles={[UserRoles.ADMIN]}>
                        <QuestionFlowPage />
                    </PrivateRoute>
                }
            />
            {/* <Route
                path="/related"
                element={
                    <PrivateRoute roles={[UserRoles.ADMIN]}>
                        <QuestionRelatedPage />
                    </PrivateRoute>
                }
            /> */}
            <Route path="*" element={<PublicRoute />} />
        </Routes>
    );
};

export default ApplicationRouter;
