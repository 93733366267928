import { FC, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AnimalTypes, VetCategories } from '../../../types/enums/question';
import {
    createQuestionRelation,
    deleteQuestionRelation,
    updateQuestionRelation,
} from '../../../hooks/swr';

interface QuestionRelationsProps {
    flow: QuestionFlowsResponse;
    // updateRelations: (vetCategories: VetCategories[], animalTypes: AnimalTypes[]) => void;
}

const QuestionRelations: FC<QuestionRelationsProps> = ({ flow }) => {
    const [relations, setRelations] = useState<Relation[]>([]);
    const [newVetCategory, setNewVetCategory] = useState<VetCategories>('' as VetCategories);
    const [newAnimalType, setNewAnimalType] = useState<AnimalTypes>('' as AnimalTypes);

    const mapRelation = (relation: RelationBackend) => ({
        id: relation.id,
        vet_category_id: String(relation.vet_category_id) as unknown as VetCategories,
        animal_type_id: String(relation.animal_type_id) as unknown as AnimalTypes,
    });

    useEffect(() => {
        const relations = flow.question_flow_animal_type_vet_category;
        if (relations) {
            setRelations(relations.map((relation) => mapRelation(relation)));
        }
    }, [flow.question_flow_animal_type_vet_category]);

    const getMenuItems = (items: typeof VetCategories | typeof AnimalTypes) =>
        Object.keys(items).map((item) => (
            <MenuItem key={item} value={items[item as keyof typeof items]}>
                {item.toLowerCase().replaceAll('_', ' ')}
            </MenuItem>
        ));

    const updateVetCategory = (index: number, vetCategory: VetCategories) => {
        if (!vetCategory) return;

        const updatedRelations = [...relations];
        updatedRelations[index].vet_category_id = vetCategory;
        setRelations(updatedRelations);
    };

    const updateAnimalType = (index: number, animalType: AnimalTypes) => {
        if (!animalType) return;

        const updatedRelations = [...relations];
        updatedRelations[index].animal_type_id = animalType;
        setRelations(updatedRelations);
    };

    const createRelation = async () => {
        if (!newVetCategory || !newAnimalType) {
            // eslint-disable-next-line no-alert
            window.alert('Fyll i "Animal Type" och "Vet Category"');
            return;
        }

        const newRelation = {
            animal_type_id: Number(newAnimalType),
            vet_category_id: Number(newVetCategory),
            question_flow_id: flow.id,
        };

        const createdRelation = await createQuestionRelation(newRelation);

        if (!createdRelation) return;
        setNewAnimalType('' as AnimalTypes);
        setNewVetCategory('' as VetCategories);
        setRelations(relations.concat(mapRelation(createdRelation)));
    };

    const updateRelation = async (relation: Relation) => {
        const newRelation = {
            id: relation.id,
            animal_type_id: Number(relation.animal_type_id),
            vet_category_id: Number(relation.vet_category_id),
            question_flow_id: flow.id,
        };
        const updatedRelation = await updateQuestionRelation(newRelation);

        if (!updatedRelation) return;
        setNewAnimalType('' as AnimalTypes);
        setNewVetCategory('' as VetCategories);
        setRelations(
            relations.map((relation) =>
                relation.id === updatedRelation.id ? mapRelation(updatedRelation) : relation,
            ),
        );
    };

    const deleteRelation = async (id?: number) => {
        if (!id) return;
        const deletedRelation = await deleteQuestionRelation(id);

        if (!deletedRelation) return;
        setRelations(relations.filter((relation) => relation.id !== id));
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Vet Category & Animal Type Relations</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ wordBreak: 'break-word' }}>
                    {relations?.length ? JSON.stringify(relations) : 'Empty'}
                </Typography>
                <br />
                {relations?.map((relation, index) => (
                    <div style={{ display: 'flex', marginTop: 6, marginBottom: 12 }} key={index}>
                        <TextField
                            select
                            fullWidth
                            label="Animal Type"
                            variant="outlined"
                            value={relation.animal_type_id}
                            onChange={(e) => updateAnimalType(index, e.target.value as AnimalTypes)}
                        >
                            {getMenuItems(AnimalTypes)}
                        </TextField>
                        <TextField
                            select
                            fullWidth
                            label="Vet Category"
                            variant="outlined"
                            value={relation.vet_category_id}
                            onChange={(e) =>
                                updateVetCategory(index, e.target.value as VetCategories)
                            }
                            style={{ marginRight: 6, marginLeft: 6 }}
                        >
                            {getMenuItems(VetCategories)}
                        </TextField>
                        <Button
                            variant="outlined"
                            onClick={() => updateRelation(relation)}
                            color="secondary"
                            style={{ width: 100, marginRight: 6 }}
                        >
                            Ändra
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => deleteRelation(relation.id)}
                            color="secondary"
                            style={{ width: 100 }}
                        >
                            Radera
                        </Button>
                    </div>
                ))}
                <div style={{ display: 'flex', marginTop: 6 }}>
                    <TextField
                        select
                        fullWidth
                        label="Animal Type"
                        variant="outlined"
                        value={newAnimalType}
                        onChange={(e) => setNewAnimalType(e.target.value as AnimalTypes)}
                    >
                        {getMenuItems(AnimalTypes)}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Vet Category"
                        variant="outlined"
                        value={newVetCategory}
                        onChange={(e) => setNewVetCategory(e.target.value as VetCategories)}
                        style={{ marginRight: 6, marginLeft: 6 }}
                    >
                        {getMenuItems(VetCategories)}
                    </TextField>
                    <Button
                        variant="outlined"
                        onClick={() => createRelation()}
                        style={{ width: 290 }}
                    >
                        Skapa
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
export default QuestionRelations;
