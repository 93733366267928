import { FC, useState } from 'react';
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Tooltip,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from '@mui/icons-material/Save';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CopyIcon from '@mui/icons-material/ContentCopy';

import UploadImage from './UploadImage';

interface SelectFlowProps {
    flows: QuestionFlowsResponse[] | undefined;
    setSelectedFlow: (flow: QuestionFlowsResponse) => void;
    selectedFlowId: number | undefined;
    createNewFlow: () => void;
    removeFlow: (id: number) => void;
    duplicateFlow: (id: number) => void;
    updateDescription: (description: string, flowId?: number) => void;
}

const SelectFlow: FC<SelectFlowProps> = ({
    flows,
    setSelectedFlow,
    selectedFlowId,
    createNewFlow,
    removeFlow,
    duplicateFlow,
    updateDescription,
}) => {
    const [description, setDescription] = useState<string>('');
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');

    const handleUpdateDescription = (flowId: number) => {
        updateDescription(description, flowId);
        setDescription('');
        setSelectedId(null);
    };

    const lockupDescription = (flow: QuestionFlowsResponse) => {
        setDescription(flow.description);
        setSelectedId(flow.id);
    };

    const handleDuplicateFlow = (id: number) => {
        duplicateFlow(id);
        setDescription('');
        setSelectedId(null);
    };

    return (
        <div>
            <ListItem disablePadding>
                <UploadImage />
            </ListItem>
            <div style={{ padding: 16, paddingBottom: 0 }}>
                <TextField
                    label="Sök"
                    variant="outlined"
                    fullWidth
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>
            {!flows || flows.length === 0 ? (
                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                    Inga flöden hittade!
                </div>
            ) : (
                <List>
                    {flows
                        .filter((flow) => flow.description.includes(searchValue))
                        .map((flow) => (
                            <ListItem
                                disablePadding
                                key={flow.id}
                                secondaryAction={
                                    <div style={{ display: 'flex', marginRight: 10 }}>
                                        {selectedId === flow.id && (
                                            <Tooltip title="Spara" arrow placement="top">
                                                <IconButton
                                                    aria-label="save"
                                                    onClick={() => handleUpdateDescription(flow.id)}
                                                    color="info"
                                                >
                                                    <SaveIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {selectedId === flow.id && (
                                            <Tooltip title="Duplicera" arrow placement="top">
                                                <IconButton
                                                    aria-label="duplicate"
                                                    onClick={() => handleDuplicateFlow(flow.id)}
                                                    color="info"
                                                >
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {selectedId === flow.id && (
                                            <Tooltip title="Ta bort" arrow placement="top">
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => removeFlow(flow.id)}
                                                    color="info"
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Tooltip
                                            title={selectedId === flow.id ? 'Avbryt' : 'Ändra'}
                                            arrow
                                            placement="top"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="open"
                                                color="info"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (selectedId === flow.id) {
                                                        setDescription('');
                                                        setSelectedId(null);
                                                    } else {
                                                        lockupDescription(flow);
                                                    }
                                                }}
                                            >
                                                <LockOpenIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            >
                                {selectedId === flow.id ? (
                                    <TextField
                                        label="Name / Description"
                                        value={description}
                                        variant="outlined"
                                        style={{ width: 210 }}
                                        onChange={(e) => setDescription(e.target.value)}
                                        autoFocus
                                        onFocus={(event) => event.target.select()}
                                    />
                                ) : (
                                    <ListItemButton
                                        selected={selectedFlowId === flow.id}
                                        onClick={() => setSelectedFlow(flow)}
                                    >
                                        <ListItemText>{`${flow.id}. ${
                                            flow.description || 'Nytt flöde'
                                        }`}</ListItemText>
                                    </ListItemButton>
                                )}
                            </ListItem>
                        ))}
                    <ListItem disablePadding>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => createNewFlow()}
                            style={{ margin: 10 }}
                        >
                            Skapa nytt flöde
                        </Button>
                    </ListItem>
                </List>
            )}
        </div>
    );
};
export default SelectFlow;
