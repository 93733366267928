import { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
    open: number | null;
    setOpen: (open: number | null) => void;
    trigger: () => void;
    text?: string;
}
const AlertDialog: FC<AlertDialogProps> = ({ open, setOpen, trigger, text }) => {
    const handleClose = () => {
        setOpen(null);
    };

    return (
        <Dialog
            open={open !== null}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Är du säker på att du vill ${text || 'ta bort'} den här?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Avbryt
                </Button>
                <Button color="secondary" onClick={trigger}>
                    {text || 'Ta bort'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AlertDialog;
