import React, { FC, ReactElement, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './Button.style';
import Spinner from '../Spinner/Spinner';
import * as colors from '../../../foundation/colors';
import { ButtonTypes } from '../../../types/enums';

interface ButtonProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'style'> {
    type: ButtonTypes;
    text?: string;
    isLoading?: boolean;
    disabled?: boolean;
    style?: string;
    compact?: boolean;
    icon?: ReactElement;
    htmlType?: 'button' | 'submit' | 'reset' | undefined;
    focusOnTab?: boolean;
    small?: boolean;
    dataTestId?: string;
}

const Button: FC<ButtonProps> = ({
    isLoading,
    disabled,
    type,
    text,
    style,
    compact,
    icon,
    htmlType = 'button',
    focusOnTab = true,
    small,
    dataTestId,
    ...rest
}) => {
    const [buttonWidth, setButtonWidth] = useState(0);
    const buttonRef = useRef<HTMLButtonElement>(null);

    // Sets the buttons initial width so that it can be used to set a minWidth
    // (stops button from resizing when displaying loading spinner)
    useLayoutEffect(() => {
        if (buttonRef.current && buttonWidth === 0) {
            setButtonWidth(buttonRef.current.clientWidth);
        }
    }, [buttonWidth]);
    const classes = styles(buttonWidth);
    return (
        <button
            ref={buttonRef}
            className={clsx(
                classes.button,

                // variants
                type === ButtonTypes.PRIMARY && classes.primaryButton,
                type === ButtonTypes.SECONDARY && classes.secondaryButton,
                type === ButtonTypes.TEXT && classes.textButton,
                type === ButtonTypes.OUTLINED && classes.outlinedButton,
                type === ButtonTypes.DESTRUCTIVE && classes.destructiveButton,
                type === ButtonTypes.LINK && classes.linkButton,
                type === ButtonTypes.ICON && classes.iconButton,
                type === ButtonTypes.GO_BACK && classes.backButton,

                // modifiers
                compact && classes.compact,
                icon && classes.withIcon,
                small && classes.small,
                style,
            )}
            disabled={disabled}
            {...(dataTestId && { 'data-testid': dataTestId })}
            /* eslint-disable-next-line react/button-has-type */
            type={htmlType}
            {...(!focusOnTab && { tabIndex: -1 })}
            {...rest}
        >
            <div className={classes.buttonContent}>
                {isLoading ? (
                    <Spinner
                        fill={
                            type === ButtonTypes.SECONDARY || type === ButtonTypes.PRIMARY
                                ? colors.neutral.white
                                : colors.primary
                        }
                    />
                ) : (
                    <>
                        {icon}
                        {text}
                    </>
                )}
            </div>
        </button>
    );
};

export default Button;
