import { FC, useState } from 'react';
import {
    TextField,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Autocomplete,
    IconButton,
    Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';

import Content from './Content';
import QuestionOptions from './QuestionOptions';
import { useQuestionFlow } from '../../../contexts/QuestionFlowContext';
import {
    QuestionDataSourceType,
    QuestionType,
    QuestionUpdateType,
} from '../../../types/enums/question';

interface QuestionProps {
    question: Question;
    updateQuestion: (question: Question) => void;
    removeQuestion: (id: number) => void;
    updateQuestionStep: (id: number, oldStep: number, newStep: number) => void;
}
const Question: FC<QuestionProps> = ({
    question,
    updateQuestion,
    removeQuestion,
    updateQuestionStep,
}) => {
    const {
        setQuestion,
        question: selected,
        translations,
        setLastQuestion,
        questionSteps,
    } = useQuestionFlow();
    const [disabled, setDisabled] = useState(true);

    const saveQuestion = () => {
        if (!selected) return;
        updateQuestion(selected);
        setLastQuestion(selected);
        setDisabled(true);
    };

    const handleResetQuestion = () => {
        setDisabled(true);
        setLastQuestion(question);
    };

    const handleUpdateQuestion = (param: keyof Question, value: QuestionValueTypes | null) => {
        if (!selected) return;
        setQuestion({ ...selected, [param]: value });
    };

    const getOptions = (value: QuestionOption[] | undefined) =>
        value ? JSON.stringify(value) : '';

    const getMenuItems = (
        items: typeof QuestionType | typeof QuestionDataSourceType | typeof QuestionUpdateType,
    ) =>
        Object.keys(items).map((item) => (
            <MenuItem key={item} value={items[item as keyof typeof items]}>
                {item}
            </MenuItem>
        ));

    const handleUpdateQuestionStep = (newStep: number) => {
        if (!selected || question.step === newStep) return;
        updateQuestionStep(question.question_flow_id, question.step, newStep);
    };

    return (
        <Accordion
            onChange={(e) => {
                e.stopPropagation();
                if (disabled) {
                    if (selected?.id === question.id) {
                        setQuestion(null);
                    } else {
                        setQuestion(question);
                    }
                }
            }}
            expanded={selected?.id === question.id}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                    <Typography variant="h5">{`Step: ${question.step}, id: ${question.id}`}</Typography>
                    {selected?.id === question.id && (
                        <div style={{ display: 'flex', marginRight: disabled ? 0 : 10 }}>
                            {!disabled ? (
                                <>
                                    <Tooltip title="Spara" arrow placement="top">
                                        <IconButton
                                            aria-label="open"
                                            color="info"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                saveQuestion();
                                            }}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title="Återställ (inga förändringar)"
                                        arrow
                                        placement="top"
                                    >
                                        <IconButton
                                            aria-label="reset"
                                            color="info"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleResetQuestion();
                                            }}
                                        >
                                            <RestartAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" arrow placement="top">
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            color="info"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeQuestion(question.id);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <Tooltip title="Ändra" arrow placement="top">
                                    <IconButton
                                        aria-label="update"
                                        color="info"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDisabled(false);
                                            setQuestion(question);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
            </AccordionSummary>
            {selected?.id === question.id && (
                <AccordionDetails>
                    <TextField
                        select
                        label="Active"
                        variant="outlined"
                        value={selected ? selected.active : question.active}
                        onChange={(e) => handleUpdateQuestion('active', e.target.value)}
                        fullWidth
                        disabled={disabled || !selected}
                    >
                        <MenuItem value={true as any}>Active</MenuItem>
                        <MenuItem value={false as any}>Inactive</MenuItem>
                    </TextField>
                    <br />
                    <br />
                    <TextField
                        select
                        label="Type"
                        variant="outlined"
                        value={selected ? selected.type || '' : question.type || ''}
                        onChange={(e) => handleUpdateQuestion('type', e.target.value)}
                        fullWidth
                        disabled={disabled || !selected}
                    >
                        {getMenuItems(QuestionType)}
                    </TextField>
                    <br />
                    <br />
                    <Autocomplete
                        disablePortal
                        options={selected ? translations : [question.title]}
                        value={selected?.title || question.title}
                        fullWidth
                        disabled={disabled || !selected}
                        onChange={(_, value: string | null) => handleUpdateQuestion('title', value)}
                        onInputChange={(_, value: string) => handleUpdateQuestion('title', value)}
                        renderInput={(params) => <TextField {...params} label="Title" />}
                        freeSolo
                    />
                    <br />
                    <Autocomplete
                        disablePortal
                        options={selected ? translations : [question.button_label]}
                        value={selected?.button_label || question.button_label}
                        fullWidth
                        disabled={disabled || !selected}
                        onChange={(_, value: string | null) =>
                            handleUpdateQuestion('button_label', value)
                        }
                        onInputChange={(_, value: string) =>
                            handleUpdateQuestion('button_label', value)
                        }
                        renderInput={(params) => <TextField {...params} label="Button Label" />}
                        freeSolo
                        // inputValue={selected?.button_label || question.button_label || undefined}
                    />
                    <br />
                    <TextField
                        select
                        label="Source Type"
                        variant="outlined"
                        value={
                            selected
                                ? selected.data_source_type || ''
                                : question.data_source_type || ''
                        }
                        onChange={(e) => handleUpdateQuestion('data_source_type', e.target.value)}
                        fullWidth
                        disabled={disabled || !selected}
                    >
                        {getMenuItems(QuestionDataSourceType)}
                    </TextField>
                    <br />
                    <br />
                    <TextField
                        select
                        label="Update Type"
                        variant="outlined"
                        value={selected ? selected.update_type || '' : question.update_type || ''}
                        onChange={(e) => handleUpdateQuestion('update_type', e.target.value)}
                        fullWidth
                        disabled={disabled || !selected}
                    >
                        {getMenuItems(QuestionUpdateType)}
                    </TextField>
                    <br />
                    <br />
                    {selected && !disabled ? (
                        <>
                            <Content
                                updateQuestion={handleUpdateQuestion}
                                content={selected.content}
                            />
                            <br />
                            <h2 style={{ color: 'red' }}>
                                Nedan uppdaterar databasen direkt (utan att man behöver spara)
                            </h2>
                            <br />
                            <QuestionOptions
                                updateQuestion={handleUpdateQuestion}
                                options={selected.question_options}
                                questionId={selected.id}
                            />
                        </>
                    ) : (
                        <>
                            <TextField
                                label="Content"
                                value={question.content ? JSON.stringify(question.content) : ''}
                                variant="outlined"
                                fullWidth
                                disabled
                            />
                            <br />
                            <br />
                            <TextField
                                label="Options"
                                value={getOptions(question.question_options)}
                                variant="outlined"
                                fullWidth
                                disabled
                            />
                            <br />
                        </>
                    )}
                    <br />
                    <TextField
                        select
                        label="Step"
                        variant="outlined"
                        value={Number(question.step)}
                        onChange={(e) => handleUpdateQuestionStep(Number(e.target.value))}
                        fullWidth
                        disabled={disabled || !selected}
                    >
                        {questionSteps.map((item) => (
                            <MenuItem key={item} value={Number(item)}>
                                {item}
                            </MenuItem>
                        ))}
                    </TextField>
                    <br />
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default Question;
