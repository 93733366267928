import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { jss } from 'react-jss';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from './store/store';
import App from './App';
import styles from './global.style';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    enabled: import.meta.env.VITE_ENV !== 'development',
    environment: import.meta.env.VITE_ENV,
});

jss.createStyleSheet(styles as any).attach();

const container = document.getElementById('root') as HTMLElement;

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Render element to the root.
root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
);
