import { Regions, TimeZones } from '../types/enums';

export const CONTENTFUL_ERRORS = {
    TECHNICAL: 'error-message-unknown',
};

export const INPUT_TYPES = {
    COMPACT: 'COMPACT',
};

export const JOURNAL_TABS = {
    JOURNAL: 0,
    PRESCRIPTION: 1,
    REFERRAL: 2,
};

export const ERRAND_TABS = {
    JOURNAL: 0,
    FOLLOW_UP: 1,
};

export const TWILIO_ROOM_EVENTS = {
    PARTICIPANT_CONNECTED: 'participantConnected',
    PARTICIPANT_DISCONNECTED: 'participantDisconnected',
    NETWORK_QUALITY_LEVEL_CHANGED: 'networkQualityLevelChanged',
} as const;

export const KEY_CODES = {
    ENTER: { key: 13, code: 'Enter' },
    ESCAPE: { key: 27, code: 'Escape' },
    ARROW_LEFT: { key: 37, code: 'ArrowLeft' },
    ARROW_UP: { key: 38, code: 'ArrowUp' },
    ARROW_RIGHT: { key: 39, code: 'ArrowRight' },
    ARROW_DOWN: { key: 40, code: 'ArrowDown' },
    TAB: { key: 9, code: 'Tab' },
};

export const TWILIO_VOICE_ERRORS = {
    JWT_EXPIRED: 31205,
};

export const AUTO_SCROLL_OPTIONS: Record<string, ScrollLogicalPosition> = {
    START: 'start',
    CENTER: 'center',
};

export const DROPDOWN_SIZES = {
    SMALL: 'small',
};

export const DROPDOWN_ALIGNMENT = {
    RIGHT: 'right',
    LEFT: 'left',
};

export const CLINIC_VISIT_STATUS = {
    URGENT: 1,
    NOT_URGENT: 2,
    NO_VISIT: 3,
};

// VALIDATION CONSTANTS

export const MAX_LENGTH = {
    TEXTAREA: 3000,
    TEXTAREA_LONG: 5000,
    TEXT_INPUT_SHORT: 100,
    TEXT_INPUT_LONG: 255,
};

export const MIN_LENGTH = {
    PASSWORD: 8,
};

export const REFERRAL_VALIDATIONS = {
    INTEGRATED: {
        REFERRAL_MESSAGE_LENGTH: 900,
    },
    NONINTEGRATED: {
        REFERRAL_MESSAGE_LENGTH: 5000,
    },
};

// NAVIGATION CONSTANTS

export const TIME_NAVIGATION_DIRECTIONS = {
    BACK: 'BACK',
    TODAY: 'TODAY',
    FORWARD: 'FORWARD',
};

// CALENDAR CONSTANTS

export const COLUMN_BLOCKS = {
    DAY: 96,
    WEEK: 24,
};

export const CALENDAR_ITEM_TIME_SPAN = {
    TIME_SLOT: 15,
};

export const CALENDAR_BLOCK_TYPE = {
    SHIFT: 'SHIFT',
    TIME_SLOT: 'TIME SLOT',
};

export const CALENDAR_CONTENT_KEYS = {
    VET_DROPDOWN_PLACEHOLDER: 'calendar-vet-dropdown-placeholder-text',
    ANIMAL_TYPE_DROPDOWN_PLACEHOLDER: 'calendar-animal-type-dropdown-placeholder-text',
    VET_CATEGORY_DROPDOWN_PLACEHOLDER: 'calendar-vet-category-dropdown-placeholder-text',
    TIMESPAN_DAY_LABEL: 'calendar-radio-button-day',
};

export const COMPACT_THRESHOLD_IN_MINUTES = {
    SHIFT: 30,
    TIME_SLOT: 15,
};

export const DEFAULT_POLLING_INTERVAL = 60000;

export const DATE_INPUTS = {
    YEAR: 0,
    MONTH: 1,
    DAY: 2,
    START_HOUR: 3,
    START_MINUTE: 4,
    END_HOUR: 5,
    END_MINUTE: 6,
};

export const DATE_INPUT_INTERVALS = ['year', 'month', 'day', 'hour', 'minute', 'hour', 'minute'];

// SPECIALIST CONSTANTS

export const SPECIALIST_PANE_SECTION_NAMES = {
    IMAGE: 'IMAGE',
    SPECIALIST: 'SPECIALIST',
    ADDRESS: 'ADDRESS',
    SPECIALIZATION: 'SPECIALIZATION',
    DESCRIPTION: 'DESCRIPTION',
};

export const CLINIC_TIME_SLOTS = {
    DAYS_PER_PAGE: 3,
};

export const REGION_TO_TIME_ZONE: Record<Regions, TimeZones> = {
    [Regions.FI]: TimeZones.FI,
    [Regions.SE]: TimeZones.SE,
    [Regions.DK]: TimeZones.DK,
    [Regions.NO]: TimeZones.NO,
    [Regions.IE]: TimeZones.IE,
};

// DEBOUNCE

export const DEBOUNCE_TIMES = {
    GOOGLE_PLACES_AUTO_COMPLETE: 250,
    ERRAND_FOLLOW_UP: 250,
};

// TEST IDS

export const VIDEO_ROOM_TESTIDS = {
    VIDEO_ICON: 'VIDEO_ICON',
    PHONE_ICON: 'PHONE_ICON',
    TOGGLE_CAMERA: 'TOGGLE_CAMERA',
    TOGGLE_MIC: 'TOGGLE_MIC',
    OPEN_SETTINGS: 'OPEN_SETTINGS',
    END_CALL: 'END_CALL',
};

export const VOICE_CALL_TESTIDS = {
    TOGGLE_CAMERA: 'TOGGLE_CAMERA',
    TOGGLE_MIC: 'TOGGLE_MIC',
    OPEN_SETTINGS: 'OPEN_SETTINGS',
    END_CALL: 'END_CALL',
};

export const CALL_FEEDBACK_TESTIDS = {
    RATING_HEADING: 'CALL_FEEDBACK_HEADER',
    SEND_COMMENT_BUTTON: 'SEND_COMMENT_BUTTON',
    SUCCESS_FEEDBACK: 'SUCCESS_FEEDBACK',
};

export const RATING_BUTTON_TESTIDS = {
    BAD: 'RATING-BUTTON-BAD',
    GOOD: 'RATING_BUTTON-GOOD',
};

export const ERRANDS_LIST_TESTIDS = {
    CLOSED_ERRANDS: 'ERRANDS_LIST_CLOSED_ERRANDS',
    OPEN_ERRANDS: 'ERRANDS_LIST_OPEN_ERRANDS',
    LIST_ITEM: 'ERRANDS_LIST_LIST_ITEM',
    ADD_ERRAND_BUTTON: 'ERRANDS_LIST_ADD_ERRAND_BUTTON',
    LOAD_MORE_BUTTON: 'ERRANDS_LIST_LOAD_MORE_BUTTON',
};

export const ERRAND_FOLLOW_UP_TESTIDS = {
    SPECIALIST_DROPDOWN: 'ERRAND_FOLLOW_UP_SPECIALIST_DROPDOWN',
};

export const ERRAND_JOURNAL_PREVIEW_MODAL_TESTIDS = {
    JOURNAL_MODAL_CONTAINER: 'ERRAND_JOURNAL_MODAL_CONTAINER',
};

export const ERRAND_JOURNAL_TESTIDS = {
    ERRAND_JOURNAL_CONTAINER: 'ERRAND_JOURNAL_CONTAINER',
    PREVIEW_BUTTON: 'ERRAND_JOURNAL_PREVIEW_BUTTON',
};

export const ERRAND_JOURNAL_CONTENTFUL_KEYS = {
    SENT_SUCCESS: 'errand-info-journal-sent',
};

export const MEETING_JOURNAL_TESTIDS = {
    PREVIEW_BUTTON: 'MEETING_JOURNAL_PREVIEW_BUTTON',
};

export const ERRAND_ACTIONS_TESTIDS = {
    MEETING_JOURNAL: 'ERRAND_ACTIONS_MEETING_JOURNAL',
};

export const MODAL_TESTIDS = {
    MODAL_CONTAINER: 'MODAL_CONTAINER',
};

export const DAY_SELECTOR_TESTIDS = {
    LEFT_ARROW: 'DAY_SELECTOR_LEFT_ARROW',
    RIGHT_ARROW: 'DAY_SELECTOR_RIGHT_ARROW',
};

export const SPINNER_TESTIDS = {
    SPINNER_SMALL: 'SPINNER_SPINNER_SMALL',
};

export const CONFIRM_BOOKING_TESTIDS = {
    EDIT_BUTTON: 'CONFIRM_BOOKING_EDIT_BUTTON',
};

export const DROPDOWN_TESTIDS = {
    CHEVRON: 'DROPDOWN_CHEVRON',
};

export const CREATE_ERRAND_PAGE_TESTIDS = {
    ERRAND_INFO_COLUMN: 'CREATE_ERRAND_ERRAND_INFO_COLUMN',
    ANIMAL_INFO_CONTAINER: 'CREATE_ERRAND_ANIMAL_INFO_CONTAINER',
    CUSTOMER_INFO_CONTAINER: 'CREATE_ERRAND_CUSTOMER_INFO_CONTAINER',
};

export const ANIMAL_INFO_TESTIDS = {
    INFO_CONTAINER: 'ANIMAL_INFO_CONTAINER',
};

export const CUSTOMER_INFO_TESTIDS = {
    INFO_CONTAINER: 'CUSTOMER_INFO_INFO_CONTAINER',
};

export const CUSTOMER_PAGE_TESTIDS = {
    ANIMAL_PANE: 'CUSTOMER_PAGE_ANIMAL_PANE',
};

export const HOVERABLE_TESTIDS = {
    HOVER_ICON: 'HOVERABLE_HOVER_ICON',
};

export const BOOKING_DETAILS_TESTIDS = {
    ANIMAL_INFO_CONTAINER: 'BOOKING_DETAILS_ANIMAL_INFO_CONTAINER',
};

export const BOOKING_INFORMATION_BODY_TESTIDS = {
    TIME_SLOT_CONTAINER: 'BOOKING_INFORMATION_BODY_TIME_SLOT_CONTAINER',
};

export const CLINIC_LIST_TESTIDS = {
    ITEM_CONTAINER: 'CLINIC_LIST_ITEM_CONTAINER',
};

export const CLINIC_LIST_ITEM_TESTIDS = {
    CLINIC_BODY: 'CLINIC_LIST_ITEM_CLINIC_BODY',
    HAS_AGREEMENT_CHECK: 'CLINIC_LIST_ITEM_HAS_AGREEMENT_CHECK',
};

export const AUTO_COMPLETE_TESTIDS = {
    AUTO_COMPLETE_CONTAINER: 'AUTO_COMPLETE_CONTAINER',
};

export const JOURNAL_PREVIEW_MODAL = {
    MODAL_CONTAINER: 'JOURNAL_PREVIEW_MODAL_CONTAINER',
};

export const MEDIA_MODAL_TEST_IDS = {
    LEFT_ARROW: 'MEDIA_MODAL_LEFT_ARROW',
    RIGHT_ARROW: 'MEDIA_MODAL_RIGHT_ARROW',
    CLOSE_BUTTON: 'MEDIA_MODAL_CLOSE_BUTTON',
    VIDEO: 'MEDIA_MODAL_VIDEO',
    AUDIO: 'MEDIA_MODAL_AUDIO',
    IMAGE: 'MEDIA_MODAL_IMAGE',
};

export const RECORDING_LIST_TESTIDS = {
    PLAY_ICON: 'RECORDING_LIST_PLAY_ICON',
    RECORDING_ROW: 'RECORDING_LIST_RECORDING_ROW',
    RECORDING_ROW_FINISHED_LOADING: 'RECORDING_LIST_RECORDING_ROW_FINISHED_LOADING',
};

export const CALENDAR_TESTIDS = {
    VETS_MENU_WRAPPER: 'CALENDAR_VETS_MENU_WRAPPER',
    ANIMAL_TYPE_MENU_WRAPPER: 'CALENDAR_ANIMAL_TYPE_MENU_WRAPPER',
    VET_CATEGORY_MENU_WRAPPER: 'VET_CATEGORY_MENU_WRAPPER',
    CALENDAR_BLOCK_GRID: 'CALENDAR_BLOCK_GRID',
};

export const SEND_JOURNALS_MODAL_DATA_TESTIDS = {
    CONTAINER: 'SEND_JOURNALS_MODAL_CONTAINER',
    SEND_BUTTON: 'SEND_JOURNALS_MODAL_SEND_BUTTON',
    CANCEL_BUTTON: 'SEND_JOURNALS_MODAL_CANCEL_BUTTON',
};

export const CUSTOM_DROPDOWN_TESTIDS = {
    DROPDOWN_INPUT: 'CUSTOM_DROPDOWN_DROPDOWN_INPUT',
    DROPDOWN_LIST: 'CUSTOM_DROPDOWN_DROPDOWN_LIST',
    CLEAR_INDICATOR: 'CUSTOM_DROPDOWN_CLEAR_INDICATOR',
};

export const NEW_DROPDOWN_TESTIDS = {
    DROPDOWN_LABEL: 'NEW_DROPDOWN_DROPDOWN_LABEL',
    DEFAULT_DROPDOWN_WRAPPER: 'NEW_DROPDOWN_DEFAULT_DROPDOWN_WRAPPER',
    CLEAR_ICON_NEXT_TO_LABEL: 'NEW_DROPDOWN_CLEAR_ICON_NEXT_TO_LABEL',
};

export const NAV_BAR_TESTIDS = {
    AVATAR: 'NAV_BAR_AVATAR',
    REGION_MENU: 'NAV_BAR_REGION_MENU',
};

export const COLLAPSIBLE_IDS = {
    ICON: 'COLLAPSIBLE_ICON',
    ICON_COLLAPSED: 'COLLAPSIBLE_ICON_COLLAPSED',
    COLLAPSIBLE: 'COLLAPSIBLE',
    BUTTON: 'COLLAPSIBLE_BUTTON',
};

export const TABLE_IDS = {
    EMPTY_TABLE_ROW: 'TABLE_EMPTY_TABLE_ROW',
    SUB_ROW: 'TABLE_SUB_ROW',
    EMPTY_BODY: 'TABLE_EMPTY_BODY',
    ROW_BUTTON: 'TABLE_ROW_BUTTON',
    CELL: 'TABLE_CELL',
};

// TOASTS
export const TOAST_ANIMATION_DELAY_TIME = 500;
export const TOAST_ANIMATION_IN_DURATION_MS = 350;
export const TOAST_ANIMATION_OUT_DURATION_MS = 500;

export const TOAST_TESTIDS = {
    CONTAINER: 'TOAST_CONTAINER',
    CLOSE_BUTTON: 'TOAST_CLOSE_BUTTON',
};

export const TOAST_CONTENT_KEYS = {
    SEND_JOURNAL_SUCCESS: 'toast-success-journal-sent',
    SEND_JOURNAL_COPIES_SUCCESS: 'toast-success-journal-copies-sent',
    SEND_REFERRAL_SUCCESS: 'toast-success-referral-sent',
    ADD_CUSTOMER_SUCCESS: 'toast-success-add-customer',
    FOLLOW_UP_MEETING_CANCELLED_SUCCESS: 'toast-success-follow-up-meeting-cancelled',
};

export const TOAST_TYPE = {
    SUCCESS: 'SUCCESS',
    SUCCESS_BOLD: 'SUCCESS_BOLD',
    WARNING: 'WARNING',
    WARNING_BOLD: 'WARNING_BOLD',
    ERROR: 'ERROR',
    ERROR_BOLD: 'ERROR_BOLD',
    INFO: 'INFO',
    INFO_BOLD: 'INFO_BOLD',
    NETWORKSTATUS: 'NETWORKSTATUS',
};

export const SPECIALIST_DATA_TESTIDS = {
    EDIT_BUTTON: 'specialist-pane-edit-button',
    CANCEL_BUTTON: 'specialist-pane-cancel-button',
    SAVE_BUTTON: 'specialist-pane-save-button',
    IMAGE_INPUT: 'specialist-image-input',
    AVATAR_SECTION: 'avatar-section',
    ADD_SPECIALIST_BUTTON: 'add-specialist-button',
    LIST_ROW: 'SPECIALIST_PANE_LIST_ROW',
    ADD_PROFILE_IMAGE_BUTTON: 'SPECIALIST_PANE_ADD_PROFILE_IMAGE_BUTTON',
    IMAGE_BUTTON_ADD: 'SPECIALIST_PANE_IMAGE_BUTTON_ADD',
};

export const GENERAL_CONTENT_KEYS = {
    KG: 'general-kilos-abbreviation',
};

export const CUSTOMER_PAGE_CONTENT_KEYS = {
    SEND_JOURNAL: 'customers-send-journal-button-text',
};
