import { createUseStyles } from 'react-jss';
import * as layout from '../../foundation/layout';
import backgroundImage from '../../assets/images/brownDog.jpg';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const styles = createUseStyles({
    page: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        backgroundSize: 'cover',
        flex: 1,
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
    },
    loginContainer: {
        height: '100%',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${layout.baseSpacing * 18}px ${layout.baseSpacing * 6}px 0`,
        width: 416,
    },
    logo: {
        marginBottom: `${layout.baseSpacing * 4}px`,
    },
    '@media (max-width: 840px)': {
        image: {
            display: 'none',
        },
        loginContainer: {
            padding: `${layout.baseSpacing * 18}px ${layout.baseSpacing * 2}px 0`,
        },
    },
    forgotPasswordButton: {
        maxWidth: 320,
        width: '100%',
        marginTop: layout.baseSpacing,
    },
    loginForm: {
        width: '100%',
    },
});

export default styles;
