import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as VetPortalLogo } from '../../assets/svgs/VetPortalLogo.svg';
import Button from '../../components/common/Button/Button';
import FormAlert from '../../components/common/FormAlert/FormAlert';
import FormikTextInput from '../../components/Formik/FormikTextInput';
import { ButtonTypes } from '../../types/enums';
import styles from './LoginPage.style';
import { initialValues, loginSchema } from './schema';
import { useAuth } from '../../contexts/AuthContext';
import { login, LoginPayload } from '../../network/requests/authentication';

const LoginPage: FC = () => {
    const classes = styles();
    const navigate = useNavigate();
    const { signInUser } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const startSignIn = async (loginData: LoginPayload) => {
        try {
            const { accessToken, refreshToken } = await login(loginData);
            await signInUser(accessToken, refreshToken);
        } catch (error) {
            setErrorMessage(t('portalLogin-error-invalid-credentials'));
        }
    };

    return (
        <div className={classes.page}>
            <div className={classes.image} />
            <div className={classes.loginContainer}>
                <div className={classes.logo}>
                    <VetPortalLogo />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={loginSchema}
                    onSubmit={startSignIn}
                    validateOnMount
                >
                    {({ isSubmitting }) => (
                        <Form className={classes.loginForm}>
                            <FormikTextInput
                                type="email"
                                name="email"
                                label={t('portalLogin-email-input-label')}
                            />
                            <FormikTextInput
                                type="password"
                                name="password"
                                label={t('portalLogin-password-input-label')}
                            />
                            {errorMessage && <FormAlert message={errorMessage} />}
                            <Button
                                text={t('portalLogin-login-button')}
                                type={ButtonTypes.SECONDARY}
                                htmlType="submit"
                                isLoading={isSubmitting}
                            />
                        </Form>
                    )}
                </Formik>
                <div className={classes.forgotPasswordButton}>
                    <Button
                        text={t('portalLogin-reset-password-button')}
                        onClick={() => navigate('/reset-password')}
                        type={ButtonTypes.TEXT}
                    />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
