import { createUseStyles } from 'react-jss';

import * as colors from '../../../foundation/colors';
import { baseSpacing } from '../../../foundation/layout';
import * as typography from '../../../foundation/typography';

const styles = createUseStyles({
    helperTextContainer: {
        height: '20px',
        marginTop: '2px',
        paddingLeft: `${baseSpacing * 1.5}px`,
    },
    helperText: {
        ...typography.footnote.bold,
        color: colors.black.mediumEmphasis,
    },
    errorText: {
        color: colors.action,
    },
});

export default styles;
