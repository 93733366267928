export enum CallRatings {
    GOOD = 'good',
    BAD = 'bad',
}

export enum DropdownTypes {
    Default = 'default',
    Text = 'text',
}

export enum JournalEmailRecipients {
    // This enum should match the enum we have in the BE (currently in journals.constants.ts)
    AgriaInjuries = 'agria-injuries',
    AgriaDirectRegulation = 'agria-direct-regulation',
}

export enum MediaType {
    VIDEO = 1,
    AUDIO = 2,
    IMAGE = 3,
}

export enum BookingActionIcons {
    VIDEOCALL = 1,
    PHONECALL = 2,
    JOURNAL = 3,
    CHECK = 4,
}

export enum BookingStatus {
    CANCELLED = 'cancel',
    FINISHED = 'finish',
    DRAFT = 'draft',
}

export enum CallConnectionType {
    VOICE = 'voice_call',
    VIDEO = 'video_call',
}

export enum CallConnectionTypeId {
    VOICE = 1,
    VIDEO = 2,
}

export enum InsuranceStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    IN_PROGRESS = 'in_progress',
    UNKNOWN = 'unknown',
}

export enum TagTypes {
    RED = 'RED',
    GREEN = 'GREEN',
    WHITE = 'WHITE',
    BLUE = 'BLUE',
}

export enum CalendarTimeSpans {
    DAY = 1,
    WEEK = 7,
}

export enum VetCategories {
    VETERINARIAN = 1,
    PRESCRIPTION = 2,
    BEHAVIOR = 4,
    BREEDER = 8,
}

export enum ItemDetailsPosition {
    RIGHT = 'RIGHT',
    LEFT = 'LEFT',
}

export enum ButtonTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    OUTLINED = 'outlined',
    DESTRUCTIVE = 'destructive',
    TEXT = 'text',
    LINK = 'link',
    ICON = 'icon',
    GO_BACK = 'back',
}

export enum KeyCodes {
    ARROW_LEFT = 37,
    ARROW_UP = 38,
    ARROW_RIGHT = 39,
    ARROW_DOWN = 40,
    TAB = 9,
    ENTER = 13,
    ESCAPE = 27,
}

export enum KeyCodeName {
    ENTER = 'Enter',
    ESCAPE = 'Escape',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_LEFT = 'ArrowLeft',
}

export enum ButtonTextStyles {
    SMALL_BOLD = 'SMALL BOLD',
}

export enum AvatarSizes {
    XXSMALL = 'xxSmall',
    XSMALL = 'xSmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum HttpError {
    NOT_FOUND = 404,
    UNPROCESSABLE_ENTITY = 422,
}

export enum HistoryTypes {
    JOURNAL = 'journal',
    CLINIC = 'clinic_appointment',
    REFERRAL = 'referral',
    DSS_JOURNAL = 'dss_journal',
}

export enum Regions {
    SE = 'SE',
    DK = 'DK',
    NO = 'NO',
    FI = 'FI',
    IE = 'IE',
}

export enum TimeZones {
    FI = 'Europe/Helsinki',
    SE = 'Europe/Stockholm',
    DK = 'Europe/Copenhagen',
    NO = 'Europe/Oslo',
    IE = 'Europe/Dublin',
}

export enum MeetingStatus {
    UPCOMING = 'upcoming',
    STARTED = 'started',
    ENDED = 'ended',
}

export enum BookingListColors {
    PRIMARY = 'primary',
    DISABLED = 'disabled',
}

export enum ErrandStatuses {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum NumberTagTypes {
    FILLED = 'filled',
    OUTLINE = 'outline',
}

export enum UserRoles {
    VET = 'vet',
    ADMIN = 'admin',
    MODERATOR = 'moderator',
    ADVISOR = 'advisor',
}

export enum ErrandStatusId {
    OPEN = 1,
    CLOSED,
}

export enum MessageType {
    ANAMNESIS = 'anamnesis',
    DIAGNOSIS = 'diagnosis',
    STATUS = 'status',
    MESSAGE_TO_OWNER = 'message_to_owner',
}

export enum Currency {
    SEK = 'SEK',
    NOK = 'NOK',
    DKK = 'DKK',
    EUR = 'EUR',
}

export enum EmployeeMail {
    VETHEM = '@vethem.se',
    AGRIA_VET_GUIDE = '@agriavetguide.com',
}

export enum PaymentMethod {
    Free = 'free',
    Klarna = 'klarna',
}

export enum VerificationType {
    Insurance = 'insurance',
    Email = 'email',
}

export enum VerificationStatus {
    Created = 'created',
    Sent = 'sent',
    Failed = 'failed',
    Verified = 'verified',
}

export enum CalendarSettingsKeys {
    Timespan = 'calendar-timespan',
    ActiveDay = 'calendar-active-day',
    SelectedVets = 'calendar-selected-vets',
    SelectedAnimalType = 'calendar-selected-animal-type',
    SelectedVetCategory = 'calendar-selected-vet-category',
}

export enum DeviceType {
    AUDIO_INPUT = 'audioinput',
    AUDIO_OUTPUT = 'audiooutput',
    VIDEO_INPUT = 'videoinput',
}
