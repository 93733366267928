import { FC, useState } from 'react';
import clsx from 'clsx';
import InputHelperBlock from '../InputHelperBlock/InputHelperBlock';
import styles from './TextInput.style';
import { KEY_CODES } from '../../../constants';

export interface TextInputProps {
    inputName: string;
    type?: string;
    label: string;
    onChange?: (value: string) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    value: string;
    error?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onSubmit?: (value: string) => void;
    infoText?: string;
    hideHelperText?: boolean;
}

const TextInput: FC<TextInputProps> = ({
    infoText,
    label,
    onChange,
    value,
    inputName,
    type = 'text',
    error,
    onBlur,
    onKeyDown,
    onSubmit,
    hideHelperText = false,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const classes = styles();

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };

    const handleOnKeyDown = (event: React.KeyboardEvent) => {
        if (onKeyDown) {
            onKeyDown(event);
        }
        if (onSubmit && event.code === KEY_CODES.ENTER.code) {
            onSubmit(value);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div className={clsx(classes.container)}>
            <div
                className={clsx(
                    classes.inputContainer,
                    isFocused && classes.activeInput,
                    error && classes.errorInput,
                )}
            >
                <label
                    className={clsx(classes.label, isFocused && classes.labelFocused)}
                    htmlFor={inputName}
                >
                    {label}
                </label>
                <input
                    id={inputName}
                    name={inputName}
                    className={classes.input}
                    value={value}
                    onChange={handleOnChange}
                    type={type}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyDown={handleOnKeyDown}
                />
            </div>
            {!hideHelperText && <InputHelperBlock error={error} infoText={infoText} />}
        </div>
    );
};

export default TextInput;
