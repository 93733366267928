import { createUseStyles } from 'react-jss';
import { baseSpacing } from '../../../foundation/layout';
import * as colors from '../../../foundation/colors';
import * as typography from '../../../foundation/typography';

import BackgroundPattern from '../../../assets/images/Alert_Background.png';

const styles = createUseStyles({
    errorMessageContainer: {
        backgroundColor: colors.backgroundRed,
        backgroundImage: `url(${BackgroundPattern})`,
        borderLeft: `2px solid ${colors.secondary}`,
        padding: baseSpacing * 2,
        marginBottom: baseSpacing * 3,
        display: 'flex',
        alignItems: 'center',
    },
    errorMessage: {
        ...typography.footnote.regular,
        color: colors.secondary,
    },
    errorIcon: {
        marginRight: baseSpacing * 2,
        width: 24,
    },
    noMargin: {
        margin: 0,
    },
});

export default styles;
