const BASE_URL = import.meta.env.VITE_BASE_URL;

export const questionFlowsUrl = (id?: number) => {
    const baseURL = `${BASE_URL}/v1/question-flows${id ? `/${id}` : ''}`;
    return baseURL;
};

export const questionUrl = (id?: number) => {
    const baseURL = `${BASE_URL}/v1/questions${id ? `/${id}` : ''}`;
    return baseURL;
};

export const questionStepUrl = () => {
    const baseURL = `${BASE_URL}/v1/questions/step`;
    return baseURL;
};

export const questionOptionUrl = (id?: number) => {
    const baseURL = `${BASE_URL}/v1/questions/option${id ? `/${id}` : ''}`;
    return baseURL;
};

export const vetCategoriesUrl = (id?: number) => {
    const baseURL = `${BASE_URL}/v1/vet-categories${id ? `/${id}` : ''}`;
    return baseURL;
};

export const publicImageUrls = () => {
    const baseURL = `${BASE_URL}/v1/question-flows/public-image-urls`;
    return baseURL;
};

export const questionFlowsRelationUrl = (id?: number) => {
    const baseURL = `${BASE_URL}/v1/question-flows/relations${id ? `/${id}` : ''}`;
    return baseURL;
};

export const questionFlowsDuplicateUrl = (id?: number) => {
    const baseURL = `${BASE_URL}/v1/question-flows/duplicate${id ? `/${id}` : ''}`;
    return baseURL;
};

export const questionFlowsImagesUrl = (name: string) => {
    const baseURL = `${BASE_URL}/v1/question-flows/images?name=${name}`;
    return baseURL;
};
