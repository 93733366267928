import { createUseStyles } from 'react-jss';
import * as colors from '../../../foundation/colors';
import { AvatarSizes } from '../../../types/enums';

const styles = createUseStyles({
    container: {
        borderRadius: '50%',
        overflow: 'hidden',
        backgroundColor: colors.neutral._200,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.03)',
        borderStyle: 'solid',
    },
    [AvatarSizes.XXSMALL]: {
        minHeight: 16,
        minWidth: 16,
        height: 16,
        width: 16,
        borderWidth: 0.5,
    },
    [AvatarSizes.XSMALL]: {
        minHeight: 24,
        minWidth: 24,
        height: 24,
        width: 24,
        borderWidth: 0.5,
    },
    [AvatarSizes.SMALL]: {
        minHeight: 48,
        minWidth: 48,
        height: 48,
        width: 48,
        borderWidth: 0.75,
    },
    [AvatarSizes.MEDIUM]: {
        minHeight: 64,
        minWidth: 64,
        height: 64,
        width: 64,
        borderWidth: 1,
    },
    [AvatarSizes.LARGE]: {
        minHeight: 96,
        minWidth: 96,
        height: 96,
        width: 96,
        borderWidth: 1,
    },
    noBorder: {
        borderWidth: 0,
        // transparent since the background color causes some images to get a small border
        backgroundColor: 'transparent',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
});

export default styles;
