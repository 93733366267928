import axios from 'axios';
import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import smartlookClient from 'smartlook-client';

import ContentGate from './components/ContentGate';
import ApplicationRouter from './routing/ApplicationRouter';
import { ToastContextProvider } from './ToastContext';
import NetworkStatus from './components/NetworkStatus/NetworkStatus';
import RegionProvider from './contexts/RegionContext';
import AuthProvider from './contexts/AuthContext';
import QuestionFlowProvider from './contexts/QuestionFlowContext';
import { AxiosProvider } from './network/interceptors';

const App: FC = () => {
    useEffect(() => {
        if (import.meta.env.VITE_ENV === 'production') {
            smartlookClient.init(import.meta.env.VITE_SMARTLOOK_KEY, {
                region: 'eu',
            });
        }
    }, []);

    return (
        <div>
            <BrowserRouter>
                <SWRConfig
                    value={{
                        fetcher: (url) => axios.get(url).then((r) => r.data),
                    }}
                >
                    <RegionProvider>
                        <AuthProvider>
                            <QuestionFlowProvider>
                                <AxiosProvider>
                                    <ContentGate>
                                        <ToastContextProvider>
                                            <>
                                                <ApplicationRouter />
                                                <NetworkStatus />
                                            </>
                                        </ToastContextProvider>
                                    </ContentGate>
                                </AxiosProvider>
                            </QuestionFlowProvider>
                        </AuthProvider>
                    </RegionProvider>
                </SWRConfig>
            </BrowserRouter>
        </div>
    );
};

export default App;
