import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from '../../components/NavBar/NavBar';
import {
    deleteQuestion,
    deleteQuestionFlow,
    duplicateQuestionFlow,
    getQuestionFlows,
    patchQuestion,
    patchQuestionStep,
    postQuestion,
    postQuestionFlow,
    updateQuestionFlow,
} from '../../hooks/swr';
import styles from './QuestionFlowPage.style';
import SelectedFlow from './SelectedFlow/SelectedFlow';
import SelectFlow from './SelectFlow/SelectFlow';
import { updateObjectInArrayById } from '../../helpers/generalHelpers';
import Preview from './Preview/Preview';
import AlertDialog from '../../components/Alert/Alert';
import { useQuestionFlow } from '../../contexts/QuestionFlowContext';
import { QuestionType } from '../../types/enums/question';

const QuestionFlowPage: FC = () => {
    const classes = styles();
    const { t } = useTranslation();
    const { setQuestion, setQuestionIds, setQuestionSteps } = useQuestionFlow();
    const [selectedFlow, setSelectedFlow] = useState<QuestionFlowsResponse | null>(null);
    const [questionFlows, setQuestionFlows] = useState<QuestionFlowsResponse[] | null>(null);
    const [removeFlow, setRemoveFlow] = useState<number | null>(null);
    const [duplicateFlow, setDuplicateFlow] = useState<number | null>(null);

    useEffect(() => {
        const getFlows = async () => {
            const flows = await getQuestionFlows();
            setQuestionFlows(flows);
        };
        void getFlows();
    }, []);

    useEffect(() => {
        const questionIds = selectedFlow?.questions?.map((question) => question.id);
        const questionSteps = selectedFlow?.questions?.map((question) => question.step);
        if (questionIds) {
            setQuestionIds(questionIds);
        }
        if (questionSteps) {
            setQuestionSteps(questionSteps);
        }
    }, [selectedFlow, setQuestionIds, setQuestionSteps]);

    const updateDescription = async (description: string, flowId?: number) => {
        if (!questionFlows || (!selectedFlow && !flowId)) return;

        const updatedFlow = await updateQuestionFlow({
            id: flowId || selectedFlow?.id,
            description,
        });
        if (!updatedFlow) return;
        const updatedFlows = updateObjectInArrayById(questionFlows, updatedFlow);
        setQuestionFlows(updatedFlows);
    };

    const updateSettings = async (settings: any) => {
        if (!questionFlows || (!selectedFlow && !settings)) return;

        const updatedFlow = await updateQuestionFlow({
            id: selectedFlow?.id,
            description: selectedFlow?.description,
            settings,
        });
        if (!updatedFlow) return;
        const updatedFlows = updateObjectInArrayById(questionFlows, updatedFlow);
        setQuestionFlows(updatedFlows);
    };

    const updateQuestion = async (question: Question) => {
        if (!questionFlows || !selectedFlow) return;

        const updatedQuestion = await patchQuestion(question);
        if (!updatedQuestion) return;

        const updatedQuestions = updateObjectInArrayById(selectedFlow.questions, updatedQuestion);
        const updatedFlow = { ...selectedFlow, questions: updatedQuestions };
        setSelectedFlow(updatedFlow);

        const updatedFlows = updateObjectInArrayById(questionFlows, updatedFlow);
        setQuestionFlows(updatedFlows);
    };

    const updateQuestionStep = async (id: number, oldStep: number, newStep: number) => {
        if (!questionFlows || !selectedFlow) return;

        const updatedQuestionFlow = await patchQuestionStep(id, oldStep, newStep);
        if (!updatedQuestionFlow) return;

        setSelectedFlow(updatedQuestionFlow);

        const updatedFlows = updateObjectInArrayById(questionFlows, updatedQuestionFlow);
        setQuestionFlows(updatedFlows);
    };

    const createNewFlow = async () => {
        const flow = await postQuestionFlow();
        const updatedFlows = questionFlows?.length ? questionFlows.concat(flow) : [flow];
        setQuestionFlows(updatedFlows);
        setSelectedFlow(flow);
    };

    const finishRemoveFlow = async () => {
        if (!questionFlows || !removeFlow) return;
        await deleteQuestionFlow(removeFlow);
        setQuestionFlows(questionFlows?.filter((flow) => flow.id !== removeFlow));
        setRemoveFlow(null);
    };

    const finishDuplicateFlow = async () => {
        if (!questionFlows || !duplicateFlow) return;
        const newFlow = await duplicateQuestionFlow(duplicateFlow);
        setQuestionFlows(questionFlows.concat(newFlow));
        setDuplicateFlow(null);
    };

    const startRemoveFlow = async (id: number) => {
        if (!questionFlows) return;
        setRemoveFlow(id);
    };

    const startDuplicateFlow = async (id: number) => {
        if (!questionFlows) return;
        setDuplicateFlow(id);
    };

    const createNewQuestion = async () => {
        if (!selectedFlow || !questionFlows) return;
        const step = selectedFlow.questions?.length
            ? Math.max(...selectedFlow.questions.map((question) => question.step))
            : 0;
        const newQuestion = {
            question_flow_id: selectedFlow.id,
            title: 'booking-prescription-tick-repellent-information-possible-prescriptions-heading',
            content: null,
            type: QuestionType.Info,
            step: step + 1,
            button_label: 'question-flow-general-button-next-screen',
            data_source_type: null,
            update_type: null,
        };
        const question = await postQuestion(newQuestion);
        if (!question) return;
        setQuestion(question);

        const updatedFlow = {
            ...selectedFlow,
            questions: selectedFlow.questions?.length
                ? [...selectedFlow.questions, question]
                : [question],
        };
        setSelectedFlow(updatedFlow);

        const updatedFlows = updateObjectInArrayById(questionFlows, updatedFlow);
        setQuestionFlows(updatedFlows);
    };

    const removeQuestion = async (id: number) => {
        if (!questionFlows || !selectedFlow) return;
        await deleteQuestion(id);

        const updatedQuestions = selectedFlow?.questions.filter((question) => question.id !== id);
        const updatedFlow = { ...selectedFlow, questions: updatedQuestions };
        setSelectedFlow(updatedFlow);

        const updatedFlows = updateObjectInArrayById(questionFlows, updatedFlow);
        setQuestionFlows(updatedFlows);
    };

    return (
        <div className={classes.QuestionFlowPage}>
            <AlertDialog open={removeFlow} setOpen={setRemoveFlow} trigger={finishRemoveFlow} />
            <AlertDialog
                open={duplicateFlow}
                setOpen={setDuplicateFlow}
                trigger={finishDuplicateFlow}
                text="duplicera"
            />
            <NavBar />
            <div className={classes.columns}>
                <div className={classes.searchColumn}>
                    <div className={classes.headerContainer}>
                        <p className={classes.headerText}>{t('Flows')}</p>
                    </div>
                    {questionFlows && (
                        <SelectFlow
                            flows={questionFlows}
                            setSelectedFlow={setSelectedFlow}
                            selectedFlowId={selectedFlow?.id}
                            createNewFlow={createNewFlow}
                            removeFlow={startRemoveFlow}
                            duplicateFlow={startDuplicateFlow}
                            updateDescription={updateDescription}
                        />
                    )}
                </div>
                {selectedFlow && (
                    <SelectedFlow
                        flow={selectedFlow}
                        updateQuestion={updateQuestion}
                        createNewQuestion={createNewQuestion}
                        removeQuestion={removeQuestion}
                        updateQuestionStep={updateQuestionStep}
                        updateSettings={updateSettings}
                    />
                )}
                {selectedFlow && <Preview />}
            </div>
        </div>
    );
};

export default QuestionFlowPage;
