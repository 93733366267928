import { FC } from 'react';
// import * as SpinnerSmall from '../../../assets/svgs/SpinnerSmall.svg';
import { ReactComponent as SpinnerSmall } from '../../../assets/svgs/SpinnerSmall.svg';
import { SPINNER_TESTIDS } from '../../../constants';
import style from './Spinner.style';

interface SpinnerProps {
    fill?: string;
}

const Spinner: FC<SpinnerProps> = ({ fill }) => {
    const classes = style({ fill });
    return (
        // <img
        //     src={
        //         <svg
        //             width="24"
        //             height="24"
        //             viewBox="0 0 24 24"
        //             fill="none"
        //             xmlns="http://www.w3.org/2000/svg"
        //         >
        //             <path d="M20.9992 12H22.9992C22.9992 5.92487 18.0744 1 11.9992 1C5.9241 1 0.999237 5.92487 0.999237 12H2.99924C2.99924 7.02944 7.02867 3 11.9992 3C16.9698 3 20.9992 7.02944 20.9992 12Z" />
        //         </svg>
        //     }
        //     className={classes.loadingSpinner}
        // />
        <SpinnerSmall
            className={classes.loadingSpinner}
            data-testid={SPINNER_TESTIDS.SPINNER_SMALL}
        />
    );
};

export default Spinner;
