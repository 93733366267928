import { AnyAction } from 'redux';
import { SET_MAPS_API_IS_LOADED } from '../actions/actionTypes';

export interface UiState {
    googleMapsApiLoaded: boolean;
}

const initialState: UiState = {
    googleMapsApiLoaded: false,
};

const uiReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_MAPS_API_IS_LOADED: {
            return {
                ...state,
                googleMapsApiLoaded: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default uiReducer;
