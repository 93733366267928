import { FC, ReactElement, useEffect } from 'react';
import clsx from 'clsx';
import styles from './Toast.style';
import { ReactComponent as CrossSmall } from '../../../assets/svgs/CrossSmall.svg';
import { TOAST_TESTIDS, TOAST_TYPE } from '../../../constants';

export const NOTIFICATION_POP_UP_CLOSING_ICON = 'NOTIFICATION_POP_UP_CLOSING_ICON';
// How long it will stay in the screen in milliseconds.
export const defaultDisplayTime = 5000;

interface ToastProps {
    message: string;
    icon?: ReactElement | null;
    displayTime?: number;
    onDismiss: () => void;
    showToast: boolean;
    fixed?: boolean; // keeps the toast on the screen until we trigger the toast to false.
    type: string;
}

const Toast: FC<ToastProps> = ({
    message,
    icon,
    displayTime = defaultDisplayTime,
    onDismiss,
    showToast = false,
    fixed = false,
    type,
}) => {
    const classes = styles({ type });

    // Automatically hide Toast after 'displayTime' milliseconds if it's not a fixed toast.
    useEffect(() => {
        if (!fixed) {
            let timer: NodeJS.Timer;
            if (showToast) {
                timer = setTimeout(() => {
                    onDismiss();
                }, displayTime);
            }

            return () => {
                clearTimeout(timer);
            };
        }
    }, [displayTime, showToast, onDismiss, fixed]);

    return (
        <div
            className={clsx(
                classes.notificationContainer,
                showToast ? classes.animationIn : classes.animationOut,
            )}
            data-testid={TOAST_TESTIDS.CONTAINER}
        >
            {icon && <div className={classes.iconContainer}>{icon}</div>}

            <p className={classes.messageText}>{message}</p>

            {type !== TOAST_TYPE.NETWORKSTATUS && (
                <div className={classes.closingButtonContainer}>
                    <button
                        type="button"
                        className={classes.closingButton}
                        onClick={() => {
                            onDismiss();
                        }}
                        data-testid={TOAST_TESTIDS.CLOSE_BUTTON}
                    >
                        <CrossSmall data-testid={NOTIFICATION_POP_UP_CLOSING_ICON} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Toast;
