import { AnyAction } from 'redux';

export interface TimeSlotState {
    timeSlots: TimeSlot[];
}

const initialState: TimeSlotState = {
    timeSlots: [],
};

const timeSlotReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default timeSlotReducer;
