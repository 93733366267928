import { createUseStyles } from 'react-jss';
import { stateOverlay } from '../../../../foundation/colors';
import { baseSpacing } from '../../../../foundation/layout';
import { body } from '../../../../foundation/typography';

const styles = createUseStyles({
    menuItemText: {
        ...body._2.regular,
    },
    container: {
        padding: baseSpacing,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: stateOverlay.hover,
            '& $menuItemText': {
                ...body._2.bold,
            },
        },
        minWidth: baseSpacing * 16,
    },
    iconContainer: {
        marginRight: baseSpacing,
    },
});

export default styles;
