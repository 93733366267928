import { createUseStyles } from 'react-jss';
import * as colors from '../../foundation/colors';
import * as typography from '../../foundation/typography';
import { baseSpacing } from '../../foundation/layout';

const styles = createUseStyles({
    QuestionFlowPage: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    columns: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        backgroundColor: colors.neutral._100,
    },
    searchColumn: {
        paddingBottom: baseSpacing * 3,
        minWidth: 370,
        borderWidth: 0,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.neutral._200,
        background: colors.neutral.white,
        overflow: 'auto',
    },
    headerContainer: {
        padding: baseSpacing * 2,
        borderBottom: [1, 'solid', colors.neutral._200],
    },
    headerText: {
        ...typography.heading._5,
    },
});

export default styles;
