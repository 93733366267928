export enum QuestionType {
    Text = 'text',
    TextLarge = 'text_large',
    TextLocation = 'text_location',
    Select = 'select',
    SelectMultiple = 'select_multiple',
    SelectMedia = 'select_media',
    Search = 'search',
    SearchMultiple = 'search_multiple',
    Upload = 'upload',
    Info = 'info',
    DatePicker = 'date',
}

export enum QuestionUpdateType {
    AnimalBirthday = 'animal_birthday',
    AnimalRace = 'animal_race',
    AnimalWeight = 'animal_weight',
    AnimalGender = 'animal_gender',
    Empty = '',
}

export enum QuestionDataSourceType {
    AnimalBreed = 'animal_breed',
    AnimalGender = 'animal_gender',
    Empty = '',
}
export enum KeyboardType {
    EmailAddress = 'email-address',
    Numeric = 'numeric',
    PhonePad = 'phone-pad',
    NumberPad = 'number-pad',
    DecimalPad = 'decimal-pad',
    Empty = '',
}

export enum AnimalTypes {
    Dog = '1',
    Cat = '2',
    Rabbit = '3',
    Bird = '4',
    Reptile = '5',
    Rodent = '6',
    Ferret = '7',
    Minipig = '8',
    Horse = '9',
    Cattle = '10',
    Sheep = '11',
    Pig = '12',
    Goat = '13',
    Other = '14',
    Empty = '',
}

export enum VetCategories {
    VETERINARIAN = '1',
    PRESCRIPTION = '2',
    BEHAVIOUR = '4',
    BREEDER = '8',
    HEALTHCHECK = '9',
    FEEDING_CONSULTATION = '10',
    Empty = '',
}
