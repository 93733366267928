import { AnyAction } from 'redux';
import { SET_SIGNED_IN, SET_SIGNED_OUT } from '../actions/actionTypes';

export interface AuthState {
    signedIn: boolean;
    user: AuthenticatedUser | Record<string, never>;
}

const initialState: AuthState = {
    signedIn: false,
    user: {},
};

const authenticationReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_SIGNED_IN: {
            return {
                ...state,
                signedIn: true,
                user: action.user,
            };
        }
        case SET_SIGNED_OUT: {
            return {
                ...state,
                signedIn: false,
                user: {},
            };
        }
        default: {
            return state;
        }
    }
};

export default authenticationReducer;
