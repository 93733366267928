import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { ReactComponent as VetGuideLogoHorizontalWhite } from '../../assets/svgs/VetGuideLogoHorizontalWhite.svg';
import Menu from '../common/Menu/Menu';
import Avatar from '../common/Avatar/Avatar';
import { AvatarSizes, Regions, UserRoles } from '../../types/enums';
import SwedenFlagAvatar from '../../assets/images/SwedenFlagAvatar.png';
import FinlandFlagAvatar from '../../assets/images/FinlandFlagAvatar.png';
import IrelandFlagAvatar from '../../assets/images/IrelandFlagAvatar.png';
import NorwayFlagAvatar from '../../assets/images/NorwayFlagAvatar.png';
import DenmarkFlagAvatar from '../../assets/images/DenmarkFlagAvatar.png';
import styles from './NavBar.style';
import NavigationLink from './NavigationLink';
import LogOutIcon from '../../assets/images/LogOutIcon.png';
import UserImagePlaceholder from './UserImagePlaceholder/UserImagePlaceholder';
import { AppState } from '../../store/store';
import { isAuthenticatedUserVet as isVet } from '../../types/guards/user';
import { NAV_BAR_TESTIDS } from '../../constants';
import { getMarkets } from '../../network/requests/market';
import { useRegion } from '../../contexts/RegionContext';
import RoleGuard from '../RoleGuard/RoleGuard';
import { useAuth } from '../../contexts/AuthContext';

export const REGION_FLAG_MAP = {
    [Regions.SE]: { image: SwedenFlagAvatar, testId: 'REGION_FLAG_TEST_ID_SE' },
    [Regions.DK]: { image: DenmarkFlagAvatar, testId: 'REGION_FLAG_TEST_ID_DK' },
    [Regions.NO]: { image: NorwayFlagAvatar, testId: 'REGION_FLAG_TEST_ID_NO' },
    [Regions.FI]: { image: FinlandFlagAvatar, testId: 'REGION_FLAG_TEST_ID_FI' },
    [Regions.IE]: { image: IrelandFlagAvatar, testId: 'REGION_FLAG_TEST_ID_IE' },
};

export const REGION_PICKER_TEST_ID = 'REGION_PICKER_TEST_ID';
export const USER_MENU_TEST_ID = 'USER_MENU_TEST_ID';

const NavBar: FC = () => {
    const [navbarHeight, setNavbarHeight] = useState(0);
    const user = useSelector((state: AppState) => state.authentication.user);
    const classes = styles(navbarHeight);
    const { t } = useTranslation();
    const { region, setRegion } = useRegion();
    const { signOutUser } = useAuth();

    const navRef = useRef<HTMLDivElement | null>(null);

    const [markets, setMarkets] = useState<MarketWithLanguages[]>([]);

    const startSignOut = () => {
        signOutUser();
    };

    useLayoutEffect(() => {
        if (navRef.current) {
            setNavbarHeight(navRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const response = await getMarkets();
                setMarkets(response);
            } catch (error) {
                captureException(error);
            }
        };
        void fetchMarkets();
    }, []);

    // const regionMenuItems = markets.map((market) => ({
    //     onClick: () => {
    //         console.log('region clicked', market.country_code);
    //         setRegion(market.country_code as Regions);
    //     },
    //     image: REGION_FLAG_MAP[market.country_code as Regions].image,
    //     testId: REGION_FLAG_MAP[market.country_code as Regions].testId,
    //     text: t(`region-${market.name.toLowerCase()}`),
    //     key: market.id,
    // }));

    const accountMenuItems = [
        {
            onClick: startSignOut,
            image: LogOutIcon,
            text: t('topMenu-logout'),
            key: 'logoutButton',
        },
    ];

    return (
        <div className={classes.navBarPlaceholder}>
            <div className={classes.navBar} ref={navRef}>
                <VetGuideLogoHorizontalWhite />
                <div className={classes.links}>
                    <RoleGuard roles={[UserRoles.ADMIN]}>
                        <>
                            <NavigationLink to="/related" text={t('Question Related')} />
                            <NavigationLink to="/flows" text={t('Question Flows')} />
                            <div
                                className={classes.regionMenuIconContainer}
                                data-testid={NAV_BAR_TESTIDS.REGION_MENU}
                            >
                                <Menu
                                    toggleMenuComponent={
                                        <Avatar
                                            image={REGION_FLAG_MAP[region].image}
                                            size={AvatarSizes.XSMALL}
                                            testId={`SELECTED_${REGION_FLAG_MAP[region].testId}`}
                                            noBorder
                                        />
                                    }
                                    testId={REGION_PICKER_TEST_ID}
                                    menuItems={[]} // regionMenuItems
                                />
                            </div>
                        </>
                    </RoleGuard>

                    <div
                        className={classes.accountMenuIconContainer}
                        data-testid={NAV_BAR_TESTIDS.AVATAR}
                    >
                        <Menu
                            toggleMenuComponent={
                                (isVet(user) && user.image) || !isVet(user) ? (
                                    <Avatar
                                        image={isVet(user) ? user.image : undefined}
                                        size={AvatarSizes.XSMALL}
                                        noBorder
                                    />
                                ) : (
                                    <UserImagePlaceholder
                                        userName={`${user.user_vet.first_name} ${user.user_vet.last_name}`}
                                    />
                                )
                            }
                            testId={USER_MENU_TEST_ID}
                            menuItems={accountMenuItems}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
