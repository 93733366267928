import { FC, useRef } from 'react';
import { Button } from '@mui/material';
import { uploadQuestionFlowImage } from '../../../hooks/swr';

const UploadImage: FC = () => {
    const imageInputRef = useRef<HTMLInputElement>(null);

    const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const image = e?.target?.files?.length ? e.target.files[0] : null;
        if (image) {
            try {
                // eslint-disable-next-line no-alert
                const name = window.prompt('Vad ska bilden heta?');
                if (name) {
                    const formData = new FormData();
                    formData.append('file', image);
                    formData.append('Content-Type', image.type);
                    // formData.append('name', name);
                    // formData.append('originalname', name);

                    const url = await uploadQuestionFlowImage(name, formData);
                    console.log(url);
                    // TODO Lägg in nya namnet i "images" listan för autoComplete
                }
            } catch (error) {
                // captureException(error);
                console.log(error);
            }
        }
        // makes sure onChange fires if you:
        // select an image -> remove the image -> select the same image again
        if (imageInputRef?.current?.value) {
            imageInputRef.current.value = '';
        }
    };
    return (
        <>
            <input
                type="file"
                id="fileSelector"
                ref={imageInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInput}
            />
            <Button
                variant="contained"
                fullWidth
                onClick={() => imageInputRef?.current?.click()}
                style={{ margin: 10 }}
            >
                Ladda upp bild
            </Button>
        </>
    );
};
export default UploadImage;
