import { FC } from 'react';
import styles from './MenuItem.style';
import Avatar from '../../Avatar/Avatar';
import { AvatarSizes } from '../../../../types/enums';

interface MenuItemProps {
    onClick: () => unknown;
    image?: string;
    text: string;
    testId?: string;
}

const MenuItem: FC<MenuItemProps> = ({ onClick, image, text, testId }) => {
    const classes = styles();
    return (
        <li className={classes.container} onClick={onClick} onKeyDown={onClick}>
            <div className={classes.iconContainer}>
                {image && (
                    <Avatar image={image} size={AvatarSizes.XSMALL} noBorder testId={testId} />
                )}
            </div>
            <p className={classes.menuItemText}>{text}</p>
        </li>
    );
};

export default MenuItem;
