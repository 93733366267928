import { createUseStyles } from 'react-jss';
import { baseSpacing } from '../../../foundation/layout';
import * as typography from '../../../foundation/typography';
import * as colors from '../../../foundation/colors';
import {
    TOAST_ANIMATION_IN_DURATION_MS,
    TOAST_ANIMATION_OUT_DURATION_MS,
    TOAST_TYPE,
} from '../../../constants';

const styles = createUseStyles({
    notificationContainer: {
        display: 'flex',
        position: 'fixed',
        zIndex: 2,
        alignItems: 'center',

        width: 340,
        minHeight: 40,
        padding: baseSpacing * 2,

        top: 72,
        // Here's how we hide the Toast. When animationOut is triggered, the toast's 'right' value will come back to this:
        right: -355,
        opacity: 0,

        backgroundColor: (props) =>
            props.type === TOAST_TYPE.NETWORKSTATUS
                ? colors.neutral._700
                : colors.white.highEmphasis,

        boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.33)',
        borderRadius: 4,
    },

    animationIn: {
        transition: `right ${
            TOAST_ANIMATION_IN_DURATION_MS / 1000
        }s cubic-bezier(0, 1, 0.5, 1), opacity ${TOAST_ANIMATION_IN_DURATION_MS / 1000}s ease-in`,

        right: 24,
        opacity: 1,
    },

    animationOut: {
        transition: `right ${
            TOAST_ANIMATION_OUT_DURATION_MS / 1000
        }s cubic-bezier(0, 1, 0.5, 1), opacity  ${TOAST_ANIMATION_OUT_DURATION_MS / 1000}s ease-in`,
    },

    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: baseSpacing * 2,

        '& svg': {
            width: (props) => (props.type === TOAST_TYPE.NETWORKSTATUS ? 24 : 16),
            height: (props) => (props.type === TOAST_TYPE.NETWORKSTATUS ? 24 : 16),
        },
    },

    messageText: {
        ...typography.footnote.bold,

        color: (props) =>
            props.type === TOAST_TYPE.NETWORKSTATUS
                ? colors.white.highEmphasis
                : colors.black.highEmphasis,
    },

    closingButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
    },

    closingButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        border: 'none',
        backgroundColor: colors.white.highEmphasis,

        '& svg': {
            width: 16,
            height: 16,
        },

        '& path': {
            fill: colors.neutral._500,
        },

        '&:hover': {
            cursor: 'pointer',
        },
    },
});

export default styles;
