import { createUseStyles } from 'react-jss';
import { primary } from '../../../foundation/colors';

const styles = createUseStyles({
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    loadingSpinner: {
        animation: '$spin 1s infinite linear',
        fill: (props) => props.fill || primary,
    },
});

export default styles;
