import { createUseStyles } from 'react-jss';
import * as colors from '../../../foundation/colors';
import * as layout from '../../../foundation/layout';
import * as typography from '../../../foundation/typography';

const styles = createUseStyles({
    userImagePlaceholder: {
        height: layout.baseSpacing * 4,
        width: layout.baseSpacing * 4,
        borderRadius: layout.baseSpacing * 2,
        backgroundColor: colors.neutral._300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    initialText: {
        color: colors.white.highEmphasis,
        ...typography.caption._1.bold,
    },
});

export default styles;
