import { FC } from 'react';
import { Button, InputAdornment, Paper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuestionFlow } from '../../../contexts/QuestionFlowContext';

import './Preview.css';
import DatePickerSvg from './DatePicker.svg';
import DogSvg from './Dog.svg';
import UploadSvg from './Upload.svg';
import { QuestionType } from '../../../types/enums/question';

const fileUrl =
    'https://agriavetguide-public-files.s3.eu-north-1.amazonaws.com/question-flow-images/';

const Preview: FC = () => {
    const { t } = useTranslation();
    const { question: selectedQuestion, lastQuestion } = useQuestionFlow();

    const question = selectedQuestion || lastQuestion;

    let imageWidth = '20%';
    if (question?.content?.imageSize) {
        switch (question.content.imageSize) {
            case 'medium':
                imageWidth = '30%';
                break;
            case 'large':
                imageWidth = '40%';
                break;
            default:
                imageWidth = '20%';
        }
    }
    return (
        <Paper
            variant="outlined"
            style={{
                padding: 20,
                margin: 16,
                marginLeft: 0,
                overflow: 'scroll',
                width: '60%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                background: '#ebeced',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    margin: 'auto',
                    color: 'darkblue',
                    flexDirection: 'column',
                    flexGrow: 0,
                    width: '100%',
                }}
            >
                {question?.type === QuestionType.Info && (
                    <img
                        style={{
                            width: imageWidth,
                            margin: 'auto',
                            marginTop: -150,
                            marginBottom: 20,
                        }}
                        src={
                            question.content?.image
                                ? `${fileUrl}${question.content?.image}`
                                : DogSvg
                        }
                        alt="dog"
                    />
                )}
                {!question && (
                    <div style={{ fontSize: 33, margin: 16 }}>
                        <b>Välj en fråga att ändra för att börja</b>
                    </div>
                )}
                <div style={{ fontSize: 23, margin: 16 }}>
                    <b>{t(question?.title || '')}</b>
                </div>
                {question?.content?.description && (
                    <div style={{ fontSize: '1.5rem', marginTop: 16 }}>
                        {t(question.content.description || '')}
                    </div>
                )}
                {question?.type === QuestionType.Select &&
                    question?.question_options?.map((option) => (
                        <div
                            key={option.id}
                            style={{
                                width: '100%',
                                display: 'flex',
                                fontSize: 16,
                                padding: 16,
                                marginTop: 10,
                                background: 'white',
                                justifyContent: 'space-between',
                            }}
                        >
                            {t(option.text)}
                            <div
                                style={{
                                    height: 18,
                                    width: 18,
                                    border: '1px solid grey',
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                    ))}
                {question?.type === QuestionType.SelectMedia && (
                    <div style={{ overflow: 'auto', height: 400, marginTop: 20 }}>
                        {question?.question_options?.map((option) => (
                            <div
                                key={option.id}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    fontSize: 16,
                                    padding: 16,
                                    marginTop: 10,
                                    background: 'white',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={`${fileUrl}${option.text}`}
                                    style={{ height: 90 }}
                                    alt="media"
                                />
                                <div
                                    style={{
                                        height: 18,
                                        width: 18,
                                        border: '1px solid grey',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {question?.type === QuestionType.TextLarge && (
                    <TextField
                        disabled
                        multiline
                        rows={20}
                        style={{
                            background: 'white',
                            border: '2px solid blue',
                            borderRadius: '1.5%',
                            marginTop: 25,
                        }}
                    />
                )}
                {question?.type === QuestionType.Upload && (
                    <img style={{ marginTop: 20, width: '50%' }} src={UploadSvg} alt="upload" />
                )}
                {[QuestionType.Search, QuestionType.Text, QuestionType.TextLocation].includes(
                    question?.type,
                ) && (
                    <TextField
                        className="textfield"
                        label={t(question?.content?.inputLabel || '')}
                        disabled
                        style={{
                            background: 'white',
                            borderRadius: '1.5%',
                            marginTop: 25,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {t(question?.content?.answerSuffix || '')}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                {question?.type === QuestionType.DatePicker && (
                    <img style={{ marginTop: 20 }} src={DatePickerSvg} alt="date" />
                )}
            </div>
            <Button
                style={{
                    height: 50,
                    marginBottom: 55,
                    fontSize: '1.5rem',
                    textTransform: 'none',
                }}
                variant="contained"
                disabled={!question?.content?.optional}
            >
                {t(question?.button_label || '')}
            </Button>
        </Paper>
    );
};

export default Preview;
