import { getRandom } from './mathHelpers';

/*
 * A media (.mp4 for example) can be a video or just an audio (no pixels).
 * To check whether it’s an audio or a video we create a <video> for the media
 *  and check whether it has some dimensions.
 *
 * No dimensions  -> Audio.
 * Has Dimensions -> Video.
 *
 * Found this suggestions here: https://stackoverflow.com/questions/32241349/how-to-detect-if-mp4-file-is-audio-or-video-with-fineuploader-or-javascript
 * */
export const isMediaVideo = (url: string) => {
    return new Promise((res) => {
        // Creating <video> HTML element.
        const video = document.createElement('video');

        video.preload = 'metadata';

        // Check whether it has some dimensions (video) or not (audio).
        video.onloadedmetadata = () => {
            // It has dimensions: It’s a Video.
            if (video.videoHeight && video.videoWidth) {
                res(true);
            } else {
                // No dimensions: It’s an Audio.
                res(false);
            }
        };

        // .onloadedmetadata is triggered once the video passed here is loaded
        video.src = url;
    });
};

export const shallowEqual = (
    object1: Record<string, unknown>,
    object2: Record<string, unknown>,
): boolean => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

export const lowercaseFirstLetter = (string: string): string => {
    if (!string || !string.length) {
        return '';
    }

    return string.charAt(0).toLocaleLowerCase() + string.slice(1);
};

export const uppercaseFirstLetter = (string: string): string => {
    if (!string || !string.length) {
        return '';
    }

    return string.charAt(0).toLocaleUpperCase() + string.slice(1);
};

export const getInitials = (name: string): string[] => {
    return name?.split(' ').map((n) => n.charAt(0).toUpperCase());
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

/**
 * Create a query string from params
 */
export const buildQuery = (params: Record<string, unknown>) => {
    const queryArray = Object.keys(params).reduce((acc: string[], key) => {
        if (params[key] !== undefined && params[key] !== null) {
            acc.push(`${key}=${params[key]}`);
        }

        return acc;
    }, []);

    return queryArray.length ? `?${queryArray.join('&')}` : '';
};

/**
 * Sort an array by first_name and last_name
 */
export function sortByFullName<T extends { first_name: string; last_name: string }>(a: T, b: T) {
    const firstNameA = a.first_name.toLowerCase();
    const firstNameB = b.first_name.toLowerCase();
    const lastNameA = a.last_name.toLowerCase();
    const lastNameB = b.last_name.toLowerCase();

    if (firstNameA < firstNameB) {
        return -1;
    }
    if (firstNameA > firstNameB) {
        return 1;
    }
    if (lastNameA < lastNameB) {
        return -1;
    }
    if (lastNameA > lastNameB) {
        return 1;
    }

    return 0;
}

/**
 * Filter out null and undefined values in a TS-safe manner
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
    return value !== undefined && value !== null;
}

/**
 * Shuffle array
 */
export const shuffleArray = (array: unknown[]) => {
    const tempArray = [...array];
    for (let i = tempArray.length - 1; i > 0; i -= 1) {
        const j = Math.floor(getRandom() * (i + 1));
        [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]];
    }

    return tempArray;
};

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const arraysAreEqual = <T>(first: T[], second: T[]): boolean =>
    first.length === second.length && first.every((element) => second.includes(element));

/**
 * Returns a new array with updated values
 * @param arr Original array
 * @param obj Updated object that needs to be updated in the array. Using ID as key
 * @returns New array
 */
export const updateObjectInArrayById = <T extends { id: number }>(arr: T[], obj: T) =>
    arr.map((item) => {
        if (item.id === obj.id) {
            return obj;
        }
        return item;
    });
