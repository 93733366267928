import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from '../store/store';
import { UserRoles } from '../types/enums';

interface PrivateRouteProps {
    roles: UserRoles[];
    children: JSX.Element;
}

const PrivateRoute = ({ roles, children }: PrivateRouteProps) => {
    const { signedIn, user } = useSelector((state: AppState) => state.authentication);

    if (!signedIn) {
        return <Navigate to="/" replace />;
    }

    if (roles.includes(user.type as UserRoles)) {
        return children;
    }

    return <Navigate to="/" replace />;
};

export default PrivateRoute;
