import { FC } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Button from '../common/Button/Button';

import styles from './NavigationLink.style';
import { ButtonTypes } from '../../types/enums';

interface NavigationLinkProps {
    to: LinkProps['to'];
    text: string;
}

const NavigationLink: FC<NavigationLinkProps> = ({ to, text }) => {
    const classes = styles();

    return (
        <NavLink
            to={to}
            className={({ isActive }) => clsx(classes.link, isActive && classes.linkActive)}
        >
            <Button type={ButtonTypes.LINK} text={text} compact />
        </NavLink>
    );
};

export default NavigationLink;
