// auth
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_SIGNED_OUT = 'SET_SIGNED_OUT';

// REVIEWS
export const SET_VET_REVIEWS = 'SET_VET_REVIEWS';
export const SET_VET_REVIEWS_PAGINATION = 'SET_VET_REVIEWS_PAGINATION';
export const SET_VET_REVIEWS_AVERAGE_RATING = 'SET_VET_REVIEWS_AVERAGE_RATING';
export const SET_VET_REVIEWS_NUMBER_OF_RATINGS = 'SET_VET_REVIEWS_NUMBER_OF_RATINGS';
export const SET_VET_REVIEWS_CURRENT_PAGE = 'SET_VET_REVIEWS_CURRENT_PAGE';
export const SET_VET_REVIEWS_PER_PAGE = 'SET_VET_REVIEWS_PER_PAGE';

// UI
export const SET_MAPS_API_IS_LOADED = 'SET_MAPS_API_IS_LOADED';
