import { FC, useState } from 'react';
import {
    TextField,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { deleteQuestionOption, postQuestionOption } from '../../../hooks/swr';
import { useQuestionFlow } from '../../../contexts/QuestionFlowContext';
import { QuestionType } from '../../../types/enums/question';

interface QuestionOptionsProps {
    options: QuestionOption[] | undefined;
    updateQuestion: (param: keyof Question, value: QuestionValueTypes) => void;
    questionId: number;
}
const QuestionOptions: FC<QuestionOptionsProps> = ({ options, updateQuestion, questionId }) => {
    const { translations, question, publicImages, questionIds } = useQuestionFlow();
    const [newText, setNewText] = useState<string>('');
    const [newNextId, setNewNextId] = useState<number>(questionId + 1);
    const updateText = (id: number, text: string) => {
        if (!options) return;

        const updatedOptions = options.map((option) =>
            option.id === id ? { ...option, text } : option,
        );
        updateQuestion('question_options', updatedOptions);
    };

    const updateNextId = (id: number, nextId: number) => {
        if (!options) return;

        const updatedOptions = options.map((option) =>
            option.id === id ? { ...option, next_question_id: nextId } : option,
        );
        updateQuestion('question_options', updatedOptions);
    };

    const nextIdExists = (nextId: number) => {
        return questionIds.find((questionId) => questionId === nextId);
    };

    const createNewOption = async () => {
        if (!newNextId || !newText) {
            // eslint-disable-next-line no-alert
            window.alert('Fyll i "text" och "next question ID"');
        } else if (!nextIdExists(newNextId)) {
            // eslint-disable-next-line no-alert
            window.alert(
                `Finns ingen fråga med ID ${newNextId}, du kanske behöver skapa en ny fråga först?`,
            );
        }

        const newOption = { text: newText, next_question_id: newNextId, question_id: questionId };
        const updatedOption = await postQuestionOption(newOption as QuestionOption);
        if (!updatedOption) return;

        const updatedOptions = options?.concat(updatedOption) || [updatedOption];
        updateQuestion('question_options', updatedOptions);
        setNewText('');
        setNewNextId(questionId + 1);
    };

    const deleteNewOption = async (id: number) => {
        await deleteQuestionOption(id);

        const updatedOptions = options?.filter((option) => option.id !== id);
        updateQuestion('question_options', updatedOptions?.length ? updatedOptions : []);
        // TODO update in "questionFlows" when context exists
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{`Question Options${
                    options?.length ? '...' : ''
                }`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ wordBreak: 'break-word' }}>
                    {options?.length ? JSON.stringify(options) : 'Empty'}
                </Typography>
                <br />
                {options?.map((option) => (
                    <div
                        style={{ display: 'flex', marginTop: 6, marginBottom: 12 }}
                        key={option.id}
                    >
                        <Autocomplete
                            disablePortal
                            options={translations}
                            value={option.text || ''}
                            fullWidth
                            onChange={(_, value: string | null) =>
                                updateText(option.id, value || '')
                            }
                            onInputChange={(_, value: string) => updateText(option.id, value || '')}
                            renderInput={(params) => <TextField {...params} label="Text" />}
                            freeSolo
                        />
                        <TextField
                            label="Next Question ID"
                            type="number"
                            value={option.next_question_id || ''}
                            variant="outlined"
                            onChange={(e) => updateNextId(option.id, Number(e.target.value))}
                            style={{ marginRight: 6, marginLeft: 6 }}
                        />
                        <Button
                            variant="outlined"
                            onClick={() => deleteNewOption(option.id)}
                            color="secondary"
                            style={{ width: 100 }}
                        >
                            Ta bort
                        </Button>
                    </div>
                ))}
                <div style={{ display: 'flex', marginTop: 6 }}>
                    <Autocomplete
                        disablePortal
                        options={
                            question?.type === QuestionType.SelectMedia
                                ? publicImages
                                : translations
                        }
                        value={newText}
                        fullWidth
                        onChange={(_, value: string | null) => setNewText(value || '')}
                        onInputChange={(_, value: string) => setNewText(value || '')}
                        renderInput={(params) => <TextField {...params} label="Text" />}
                        freeSolo
                    />
                    <TextField
                        label="Next Question ID"
                        type="number"
                        value={newNextId}
                        variant="outlined"
                        onChange={(e) => setNewNextId(Number(e.target.value))}
                        style={{ marginRight: 6, marginLeft: 6 }}
                        error={!nextIdExists(newNextId)}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => createNewOption()}
                        style={{ width: 100 }}
                    >
                        Skapa
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default QuestionOptions;
