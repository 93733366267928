import { FC, useState, useRef, useEffect } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { usePrevious } from '../../hooks';

import TextInput, { TextInputProps } from '../common/TextInput/TextInput';
import { BaseProps } from './types';

type FormikTextInputProps = BaseProps & Omit<TextInputProps, 'inputName' | 'value'>;

const FormikTextInput: FC<FormikTextInputProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const { status } = useFormikContext();
    const previousStatus = usePrevious(status);
    const [errorTextBE, setErrorTextBE] = useState('');
    const submittedValue = useRef();

    const handleInputChange = (value: string) => {
        /**
         * If user has written the same value that was rejected by BE, display BE error message again
         */
        if (submittedValue.current === value && status && status[name]) {
            setErrorTextBE(status[name]);
        } else {
            setErrorTextBE('');
        }
        helpers.setValue(value);
    };

    useEffect(() => {
        /**
         * Only set BE error message when BE error message has changed
         * This check is necessary to only update the "submittedValue" when the BE sends a new error (e.g. form is submitted)
         */

        if (status && (previousStatus && previousStatus[name]) !== (status && status[name])) {
            setErrorTextBE(status[name]);
            submittedValue.current = meta.value;
        }
    }, [previousStatus, meta.value, name, status]);

    return (
        <Field
            {...props}
            name={name}
            inputName={name}
            component={TextInput}
            value={field.value}
            onBlur={field.onBlur}
            onChange={handleInputChange}
            error={meta.touched ? meta.error || errorTextBE : undefined}
        />
    );
};

export default FormikTextInput;
