import { AnyAction } from 'redux';
import {
    SET_VET_REVIEWS,
    SET_VET_REVIEWS_AVERAGE_RATING,
    SET_VET_REVIEWS_CURRENT_PAGE,
    SET_VET_REVIEWS_NUMBER_OF_RATINGS,
    SET_VET_REVIEWS_PAGINATION,
    SET_VET_REVIEWS_PER_PAGE,
} from '../actions/actionTypes';

export interface VetReviewState {
    vetReviews: VetReview[];
    currentPage: number;
    vetReviewsPerPage: number;
    averageRating: number;
    numberOfRatings: number;
    pagination: {
        itemCount: number;
        itemsPerPage: number;
        totalItems: number;
        totalPages: number;
        currentPage: number;
    };
}

const VET_REVIEWS_PER_PAGE = 25;

const initialState: VetReviewState = {
    vetReviews: [],
    currentPage: 1,
    vetReviewsPerPage: VET_REVIEWS_PER_PAGE,
    averageRating: 0,
    numberOfRatings: 0,
    pagination: {
        itemCount: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
    },
};

const vetReviewReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_VET_REVIEWS: {
            return { ...state, vetReviews: action.vetReviews };
        }
        case SET_VET_REVIEWS_PAGINATION: {
            return { ...state, pagination: action.pagination };
        }
        case SET_VET_REVIEWS_AVERAGE_RATING: {
            return { ...state, averageRating: action.averageRating };
        }
        case SET_VET_REVIEWS_NUMBER_OF_RATINGS: {
            return { ...state, numberOfRatings: action.numberOfRatings };
        }
        case SET_VET_REVIEWS_CURRENT_PAGE: {
            return { ...state, currentPage: action.currentPage };
        }
        case SET_VET_REVIEWS_PER_PAGE: {
            return { ...state, vetReviewsPerPage: action.vetReviewsPerPage };
        }
        default: {
            return state;
        }
    }
};

export default vetReviewReducer;
