import { createUseStyles } from 'react-jss';
import * as layout from '../../foundation/layout';
import * as colors from '../../foundation/colors';

const styles = createUseStyles({
    link: {
        textDecoration: 'none',
    },
    linkActive: {
        '& button': {
            backgroundColor: colors.primary,
            borderRadius: 0,
            borderBottom: [layout.baseSpacing / 2, 'solid', colors.active],
        },
    },
});

export default styles;
