import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import {
    AudioTrack,
    LocalTrackPublication,
    Participant,
    RemoteTrackPublication,
    VideoTrack,
} from 'twilio-video';
import { millisecondsToNextMinute } from '../helpers/dateHelpers';
import { AppState } from '../store/store';
import { SET_MAPS_API_IS_LOADED } from '../store/actions/actionTypes';
import { Regions, UserRoles } from '../types/enums';

// JS setInterval is not 100% accurate and has a tendency to drift a little
// This can cause some issues when running the interval for a longer time
// This function tries to compensate for that dirft to make the interval more accurate and dependable
// Inspiration: https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
const setAccurateInterval = (callback: () => void, interval: number) => {
    let expected = Date.now() + interval; // Time when callback is supposed to run

    let intervalObject: NodeJS.Timeout;

    const step = () => {
        const drift = Date.now() - expected; // the drift (how many ms away from the expected time the callback is being called)

        if (drift > interval) {
            // something really bad happened. Maybe the browser (tab) was inactive?
            // possibly special handling to avoid futile "catch up" run
        }

        callback();

        expected += interval; // set new expected value for when callback should run in the next iteration
        intervalObject = setTimeout(step, Math.max(0, interval - drift)); // run function again and adjust for the drift
    };
    intervalObject = setTimeout(step, interval);

    return intervalObject;
};

// Runs callback every whole minute (00 - seconds)
export const useRunEveryMinute = (callback: () => void): void => {
    useEffect(() => {
        // wait until the next whole minute until starting interval
        let everyMinuteInterval: NodeJS.Timeout;
        const nextMinuteTimout = setTimeout(() => {
            //     // set interval to run every minute
            everyMinuteInterval = setAccurateInterval(callback, 60000);

            // run directly when timeout has ended (because interval will run first time after a minute has passed)
            callback();
        }, millisecondsToNextMinute());

        // run when hook is mounted
        callback();

        return () => {
            clearTimeout(nextMinuteTimout);
            clearInterval(everyMinuteInterval);
        };
    }, [callback]);
};

export const useRunEverySecond = <T>(callback: () => T): void => {
    useEffect(() => {
        callback();

        const interval = setInterval(() => {
            callback();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [callback]);
};

export const usePrevious = <T>(value: T): T => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current as T;
};

export const useQuery = (): Record<string, string> => {
    const location = useLocation();
    const queries = new URLSearchParams(location.search);

    const params: Record<string, string> = {};

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of queries.entries()) {
        params[key] = value;
    }

    return params;
};

export const useOutsideAlerter = <T>(
    ref: MutableRefObject<HTMLElement> | MutableRefObject<null>,
    callback: (target: Element | null) => T,
): void => {
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback(event.target as Element);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, callback]);
};

export const useDebounce = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(() => {
        let timer: NodeJS.Timer;
        if (value) {
            timer = setTimeout(() => setDebouncedValue(value), delay);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
};

export const useTextInputDebounce = (
    input: string,
    callback: ((value: string) => any) | undefined,
) => {
    const debounceDelay = 750;
    const extraLoadingTime = 2000;
    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [recentlyLoaded, setRecentlyLoaded] = useState(false);
    const debouncedInput = useDebounce<string>(input, debounceDelay);
    const previousDebounceInput = usePrevious(debouncedInput);
    const callbackInstanceRef = useRef(0);
    const timeoutRef = useRef<number | undefined>();
    const secondTimeoutRef = useRef<number | undefined>();
    const initialInputRef = useRef(input);
    const hasStartedTyping = useRef(false);

    const isMounted = useRef(true);

    useEffect(() => {
        if (callback) {
            if (initialInputRef.current !== input) {
                hasStartedTyping.current = true;
            }
            if (hasStartedTyping.current) {
                setLoading(true);
                setError(false);
                setSaved(false);
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            }
        }
    }, [callback, input]);

    useEffect(() => {
        if (callback && input === debouncedInput && hasStartedTyping.current) {
            setSaved(true);
            secondTimeoutRef.current = window.setTimeout(() => {
                setLoading(false);
            }, extraLoadingTime);
        }
        return () => {
            clearTimeout(secondTimeoutRef.current);
        };
    }, [input, debouncedInput, callback]);

    useEffect(() => {
        // a state to know if the input has finished loading within the last 2 seconds.
        if (callback && !loading && hasStartedTyping.current) {
            setRecentlyLoaded(true);
            const recentlyLoadedTimeout = setTimeout(() => {
                setRecentlyLoaded(false);
            }, extraLoadingTime);
            return () => {
                clearTimeout(recentlyLoadedTimeout);
            };
        }
    }, [callback, loading]);

    useEffect(
        () => () => {
            isMounted.current = false;
        },
        [],
    );

    useEffect(() => {
        const runCallback = async () => {
            callbackInstanceRef.current += 1;
            const localCallbackInstance = callbackInstanceRef.current;
            setSaved(false);
            if (callback) {
                const callbackStatus = await callback(debouncedInput);

                // a check to know if this is the last time the callback will run.
                // (in case of multiple callbacks running at the same time)
                if (localCallbackInstance === callbackInstanceRef.current && isMounted.current) {
                    if (callbackStatus === true) {
                        setError(false);
                        setSaved(true);
                    } else if (callbackStatus === false) {
                        setError(true);
                        setSaved(false);
                    }
                    // adds some extra loading time since the callback usually takes a couple of ms
                    timeoutRef.current = window.setTimeout(() => {
                        setLoading(false);
                    }, extraLoadingTime);
                }
            }
        };
        if (callback && hasStartedTyping.current && debouncedInput !== previousDebounceInput) {
            void runCallback();
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [callback, debouncedInput, previousDebounceInput]);
    return { saved, loading, error, recentlyLoaded };
};

const MAPS_API_KEY: string = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '';
const initialOptions: LoaderOptions = {
    apiKey: MAPS_API_KEY,
    libraries: ['places'],
    language: 'sv',
    region: Regions.SE,
};
export const useGoogleMapsPlacesApi = (options?: LoaderOptions): { isLoaded: boolean } => {
    const mapsApiIsSetup = useSelector((state: AppState) => state.ui.googleMapsApiLoaded);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!mapsApiIsSetup) {
            const loader = new Loader({
                ...initialOptions,
                ...options,
            });
            void loader.load().then(() => {
                dispatch({
                    type: SET_MAPS_API_IS_LOADED,
                });
            });
        }
    }, [mapsApiIsSetup, options, dispatch]);
    return { isLoaded: mapsApiIsSetup };
};

export const useAutoScroll = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    trigger: boolean | string | number | undefined,
    block: ScrollLogicalPosition,
): void => {
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ block });
        }
    }, [trigger, block, ref]);
};

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

export const usePublications = (participant: Participant) => {
    const [publications, setPublications] = useState<TrackPublication[]>([]);

    useEffect(() => {
        // Reset the publications when the 'participant' variable changes.
        setPublications(Array.from(participant.tracks.values()) as TrackPublication[]);

        const publicationAdded = (publication: TrackPublication) => {
            return setPublications((prevPublications) => [...prevPublications, publication]);
        };
        const publicationRemoved = (publication: TrackPublication) => {
            return setPublications((prevPublications) =>
                prevPublications.filter((p) => p !== publication),
            );
        };

        participant.on('trackPublished', publicationAdded);
        participant.on('trackUnpublished', publicationRemoved);
        return () => {
            participant.off('trackPublished', publicationAdded);
            participant.off('trackUnpublished', publicationRemoved);
        };
    }, [participant]);

    return publications;
};

export const useTrack = (
    publication: LocalTrackPublication | RemoteTrackPublication | undefined,
) => {
    const [track, setTrack] = useState(publication && publication.track);

    useEffect(() => {
        // Reset the track when the 'publication' variable changes.
        setTrack(publication && publication.track);

        if (publication) {
            const removeTrack = () => setTrack(null);

            publication.on('subscribed', setTrack);
            publication.on('unsubscribed', removeTrack);
            return () => {
                publication.off('subscribed', setTrack);
                publication.off('unsubscribed', removeTrack);
            };
        }
    }, [publication]);

    return track;
};

/*
 * This hook allows components to reliably use the 'mediaStreamTrack' property of
 * an AudioTrack or a VideoTrack. Whenever 'localTrack.restart(...)' is called, it
 * will replace the mediaStreamTrack property of the localTrack, but the localTrack
 * object will stay the same. Therefore this hook is needed in order for components
 * to rerender in response to the mediaStreamTrack changing.
 */
export const useMediaStreamTrack = (track?: AudioTrack | VideoTrack) => {
    const [mediaStreamTrack, setMediaStreamTrack] = useState(track?.mediaStreamTrack);

    useEffect(() => {
        setMediaStreamTrack(track?.mediaStreamTrack);

        if (track) {
            const handleStarted = () => setMediaStreamTrack(track.mediaStreamTrack);
            track.on('started', handleStarted);
            return () => {
                track.off('started', handleStarted);
            };
        }
    }, [track]);

    return mediaStreamTrack;
};

/**
 * Returns true if the given feature flag is enabled for your user id.
 *
 * @param feature
 */
export const useFeature = (feature: string): boolean => {
    const { featureFlags } = useSelector((state: AppState) => state.authentication.user);
    return featureFlags && featureFlags.includes(feature);
};

export const useUserIsAdmin = () => {
    const { type: userRole } = useSelector((state: AppState) => state.authentication.user);
    return userRole === UserRoles.ADMIN;
};

export const useUserIsVet = () => {
    const { type: userRole } = useSelector((state: AppState) => state.authentication.user);
    return userRole === UserRoles.VET;
};
