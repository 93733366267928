import { FC, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';

import Question from './Question';
import AlertDialog from '../../../components/Alert/Alert';
import QuestionRelations from './QuestionRelations';
import QuestionSettings from './QuestionSettings';

const splitQuestions = (questions: Question[]) => {
    const result: { active: Question[]; inactive: Question[] } = {
        active: [],
        inactive: [],
    };

    questions.forEach((question) => {
        if (question.active) {
            result.active.push(question);
        } else {
            result.inactive.push(question);
        }
    });

    return result;
};

interface SelectedFlowProps {
    flow: QuestionFlowsResponse;
    updateQuestion: (question: Question) => void;
    createNewQuestion: () => void;
    removeQuestion: (id: number) => void;
    updateQuestionStep: (id: number, oldStep: number, newStep: number) => void;
    updateSettings: (settings: any) => void;
}
const SelectedFlow: FC<SelectedFlowProps> = ({
    flow,
    updateQuestion,
    createNewQuestion,
    removeQuestion,
    updateQuestionStep,
    updateSettings,
}) => {
    const [removeQuestionAlert, openRemoveQuestionAlert] = useState<number | null>(null);

    const finishRemoveQuestion = async () => {
        if (!removeQuestionAlert) return;
        removeQuestion(removeQuestionAlert);
        openRemoveQuestionAlert(null);
    };

    const startRemoveQuestion = async (id: number) => {
        openRemoveQuestionAlert(id);
    };

    const { active, inactive } = splitQuestions(flow.questions);

    return (
        <Paper
            variant="outlined"
            style={{ padding: 20, margin: 16, overflow: 'scroll', width: '100%' }}
        >
            <AlertDialog
                open={removeQuestionAlert}
                setOpen={openRemoveQuestionAlert}
                trigger={finishRemoveQuestion}
            />
            <QuestionRelations flow={flow} />
            <QuestionSettings flowSettings={flow.settings} updateSettings={updateSettings} />
            <Typography variant="h5" margin="5px" marginTop="15px">
                Aktiva frågor
            </Typography>
            {active?.map((question) => (
                <Question
                    key={question.id}
                    question={question}
                    updateQuestion={updateQuestion}
                    removeQuestion={startRemoveQuestion}
                    updateQuestionStep={updateQuestionStep}
                />
            ))}
            <Typography variant="h5" margin="5px" marginTop="15px">
                Inaktiva frågor
            </Typography>
            {inactive?.map((question) => (
                <Question
                    key={question.id}
                    question={question}
                    updateQuestion={updateQuestion}
                    removeQuestion={startRemoveQuestion}
                    updateQuestionStep={updateQuestionStep}
                />
            ))}
            <Button
                variant="contained"
                fullWidth
                onClick={() => createNewQuestion()}
                style={{ marginTop: 10, height: 50, fontSize: 18 }}
            >
                Skapa ny fråga
            </Button>
        </Paper>
    );
};
export default SelectedFlow;
