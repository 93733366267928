import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthState } from './reducers/authReducer';
import timeSlotReducer, { TimeSlotState } from './reducers/timeSlotReducer';
import vetReviewsReducer, { VetReviewState } from './reducers/vetReviewReducer';
import uiReducer, { UiState } from './reducers/uiReducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface AppState {
    authentication: AuthState;
    timeSlot: TimeSlotState;
    ui: UiState;
    vetReview: VetReviewState;
}

export const appReducer = combineReducers({
    authentication: authReducer,
    timeSlot: timeSlotReducer,
    ui: uiReducer,
    vetReview: vetReviewsReducer,
});

export default createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));
