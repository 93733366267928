import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Regions } from '../types/enums';
import { useGetUserInfo } from '../network/requests/user';
import { SET_SIGNED_IN, SET_SIGNED_OUT } from '../store/actions/actionTypes';
import { isAuthenticatedUserVet as isVet } from '../types/guards/user';
import { useRegion } from './RegionContext';
import { logout } from '../network/requests/authentication';

export interface AuthContextValues {
    signInUser: (accessToken: string, refreshToken: string) => void;
    signOutUser: () => void;
}

export const AuthContext = createContext<AuthContextValues | undefined>(undefined);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [hasStoredTokens, setHasStoredTokens] = useState(false);

    const { setRegion } = useRegion();

    const dispatch = useDispatch();
    const user = useGetUserInfo(hasStoredTokens);

    useEffect(() => {
        if (user) {
            dispatch({ type: SET_SIGNED_IN, user });

            if (isVet(user)) {
                setRegion((user.market?.country_code as Regions) || Regions.SE);
            }
        }
    }, [dispatch, setRegion, user, hasStoredTokens]);

    const signInUser = useCallback(async (accessToken: string, refreshToken: string) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        setHasStoredTokens(true);
    }, []);

    const signOutUser = useCallback(async () => {
        try {
            await logout();

            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            setHasStoredTokens(false);

            dispatch({ type: SET_SIGNED_OUT });
        } catch (error) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }, [dispatch]);

    const refreshUserSession = useCallback(async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (accessToken && refreshToken) {
            setHasStoredTokens(true);
        } else {
            setHasStoredTokens(false);
        }
    }, []);

    useEffect(() => {
        const mountApp = async () => {
            await refreshUserSession();
        };
        void mountApp();
    }, [refreshUserSession]);

    const providerValues = useMemo(
        () => ({
            signInUser,
            signOutUser,
        }),
        [signInUser, signOutUser],
    );

    return <AuthContext.Provider value={providerValues}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
