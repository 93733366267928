import { FC } from 'react';
import clsx from 'clsx';

import { AvatarSizes } from '../../../types/enums';

import styles from './Avatar.style';

interface AvatarProps {
    image?: string | Blob | null;
    size: AvatarSizes;
    style?: string;
    noBorder?: boolean;
    testId?: string;
}

const Avatar: FC<AvatarProps> = ({ image, size, style, noBorder, testId }) => {
    const classes = styles();
    const formattedImage = image instanceof Blob ? URL.createObjectURL(image) : image;
    return (
        <div
            className={clsx(
                classes.container,
                classes[size],
                style,
                noBorder && image && classes.noBorder,
            )}
        >
            {image && (
                <img
                    data-testid={testId}
                    src={formattedImage || undefined}
                    alt="avatar"
                    className={classes.image}
                />
            )}
        </div>
    );
};

export default Avatar;
