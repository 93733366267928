import { ReactElement, useState, createContext, useMemo, useCallback } from 'react';
import Toast from './components/common/Toast/Toast';
import { noop } from './helpers/generalHelpers';
import { TOAST_ANIMATION_OUT_DURATION_MS } from './constants';

export type ToastType = {
    message: string;
    icon: ReactElement | null;
    fixed?: boolean;
    type: string;
};
type ToastProps = {
    toggleToast: (show: boolean, delay?: number) => void;
    setToast: (toast: ToastType) => void;
};

const ToastContext = createContext<ToastProps>({
    toggleToast: noop,
    setToast: noop,
});

export default ToastContext;

export const ToastContextProvider = ({ children }: { children: JSX.Element }) => {
    const [toast, setToast] = useState<ToastType | undefined>({
        message: '',
        icon: null,
        fixed: false,
        type: '',
    });
    const [showToast, setShowToast] = useState<boolean>(false);

    const toggleToast = useCallback((show: boolean, delay = 0) => {
        setTimeout(() => {
            setShowToast(show);

            // In case we are hiding the Toast, let's make sure we
            //  remove it from the DOM once the animation is done.
            if (!show) {
                setTimeout(() => {
                    setToast(undefined);
                }, TOAST_ANIMATION_OUT_DURATION_MS);
            }
        }, delay);
    }, []);

    const contextValues = useMemo(() => ({ setToast, toggleToast }), [setToast, toggleToast]);

    return (
        <ToastContext.Provider value={contextValues}>
            {children}
            {toast && (
                <Toast
                    message={toast.message}
                    icon={toast.icon}
                    showToast={showToast}
                    onDismiss={() => setShowToast(false)}
                    fixed={toast.fixed}
                    type={toast.type}
                />
            )}
        </ToastContext.Provider>
    );
};
