import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Settings } from 'luxon';
import { useSelector } from 'react-redux';
import { Regions, UserRoles } from '../types/enums';
import { REGION_TO_TIME_ZONE } from '../constants';
import { AppState } from '../store/store';

export interface RegionContextValues {
    region: Regions;
    setRegion: (region: Regions) => void;
}

const RegionContext = createContext<RegionContextValues | undefined>(undefined);

type RegionProviderDefaultValues = {
    region: Regions;
};

interface RegionProviderProps extends PropsWithChildren {
    defaultValues?: RegionProviderDefaultValues;
}

const RegionProvider: FC<RegionProviderProps> = ({ children, defaultValues }) => {
    const [region, setProviderRegion] = useState(defaultValues?.region || Regions.SE);

    const user = useSelector((state: AppState) => state.authentication.user);

    const setRegion = useCallback((newRegion: Regions) => {
        Settings.defaultZone = REGION_TO_TIME_ZONE[newRegion];
        setProviderRegion(newRegion);
    }, []);

    // Makes sure timezone is updated when region is changed
    useEffect(() => {
        // Only possible to do if you are an admin
        if (user?.type === UserRoles.ADMIN) {
            Settings.defaultZone = REGION_TO_TIME_ZONE[region];
        }
    }, [region, user]);

    const providerValues = useMemo(
        () => ({
            region,
            setRegion,
        }),
        [region, setRegion],
    );

    return <RegionContext.Provider value={providerValues}>{children}</RegionContext.Provider>;
};

export default RegionProvider;

export const useRegion = () => {
    const context = useContext(RegionContext);
    if (context === undefined) {
        throw new Error('useRegion must be used within a RegionProvider');
    }
    return context;
};
