import { createUseStyles } from 'react-jss';
import * as colors from '../../../foundation/colors';
import * as typography from '../../../foundation/typography';
import { baseSpacing } from '../../../foundation/layout';

const textButtonStyle = {
    background: 'transparent',
    '&::before': {
        display: 'none', // Text style buttons don't utilize the ::before pseudo element overlay, so we should not display it in order to avoid issues
    },
};

const blueTextButtonStyle = {
    ...textButtonStyle,
    color: colors.prussianBlue,

    '&:hover:enabled': {
        backgroundColor: colors.blue.hover,
    },

    '&:active:enabled, &:active:hover:enabled': {
        backgroundColor: colors.blue.focus,
    },
};

const styles = createUseStyles({
    // general button styling
    button: {
        width: '100%',
        padding: [baseSpacing, baseSpacing * 3],
        minHeight: baseSpacing * 6,
        border: 'none',
        borderRadius: baseSpacing * 0.5,
        ...typography.callout._1.bold,
        color: colors.neutral.white,
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',

        // button states
        '&:focus': {
            outline: 'none',
        },

        '&:disabled': {
            color: colors.black.disabled,
            cursor: 'not-allowed',
        },

        // The ::before pseudo-element is used for overlaying the button to darken its background color.
        // This element should not exist if button is disabled (therefore :enabled) as it will interfere with active/hover states which should not exist on a disabled button.
        '&:enabled::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0,
            zIndex: 0,
        },

        '&:active, &:hover': {
            '&::before': {
                opacity: 1,
            },
        },

        '&:active': {
            '&::before': {
                background: colors.stateOverlay.pressed,
            },
        },

        '&:hover:active': {
            '&::before': {
                background: colors.stateOverlay.pressed,
            },
        },

        '&:hover:enabled': {
            '&::before': {
                background: colors.stateOverlay.hover,
            },
        },
    },
    buttonContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
    },

    // modifiers
    small: {
        ...typography.callout._2.bold,
        minHeight: baseSpacing * 4,
        padding: [baseSpacing, baseSpacing * 2],
    },
    compact: {
        width: 'auto',
        minWidth: (width) => width,
    },
    withIcon: {
        justifyContent: 'space-between',
    },

    // button variants
    primaryButton: {
        background: colors.action,

        '&:disabled': {
            background: colors.neutral._200,
        },
    },
    secondaryButton: {
        background: colors.prussianBlue,
        color: colors.neutral.white,

        '&:disabled': {
            background: colors.neutral._200,
        },
    },
    textButton: {
        ...blueTextButtonStyle,
    },
    outlinedButton: {
        ...blueTextButtonStyle,
        border: `2px solid ${colors.prussianBlue}`,
    },
    destructiveButton: {
        ...textButtonStyle,
        color: colors.red._700,

        '&:hover:enabled': {
            backgroundColor: colors.red.hover,
        },
        '&:active:enabled, &:active:hover:enabled': {
            backgroundColor: colors.red.active,
        },
    },
    backButton: {
        ...textButtonStyle,
        color: colors.prussianBlue,
        minHeight: 'auto',
        padding: 0,
    },
    linkButton: {
        background: colors.prussianBlue,
        borderBottom: [baseSpacing / 2, 'solid', 'transparent'],
    },
    iconButton: {
        width: 'auto',
        minHeight: 0,
        borderRadius: '50%',
        padding: 0,
        '&::before': {
            display: 'none', // Icon buttons don't utilize the ::before pseudo element overlay, so we should not display it in order to avoid issues
        },
    },

    // animations
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
});

export default styles;
