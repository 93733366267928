import { FC, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface QuestionSettingsProps {
    flowSettings: any;
    updateSettings: (settings: any) => void;
}

const QuestionSettings: FC<QuestionSettingsProps> = ({ flowSettings, updateSettings }) => {
    const [settings, setSettings] = useState(flowSettings);
    const [paramName, setParamName] = useState('');
    const [paramValue, setParamValue] = useState('');

    useEffect(() => {
        setSettings(flowSettings);
    }, [flowSettings]);

    const handleUpdateSettings = (updatedSettings: any) => {
        updateSettings(updatedSettings);
        setSettings(updatedSettings);
    };

    const handleNewSettings = () => {
        const newSettings = { ...settings, [paramName]: paramValue };
        updateSettings(newSettings);
        setSettings(newSettings);
        setParamName('');
        setParamValue('');
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={{ wordBreak: 'break-word' }}>
                    {(settings && JSON.stringify(settings, null, 2)) || 'Empty'}
                </Typography>
                <br />
                {settings &&
                    Object.keys(settings)?.map((setting) => (
                        <div
                            style={{ display: 'flex', marginTop: 6, marginBottom: 12 }}
                            key={setting}
                        >
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                value={setting}
                                onChange={(e) => {
                                    setSettings(
                                        Object.keys(settings).map((setting2) => {
                                            if (setting2 === setting) {
                                                return { [e.target.value]: settings[setting2] };
                                            }
                                            return { [setting2]: settings[setting2] };
                                        }),
                                    );
                                    const newSettings = { ...settings };
                                    newSettings[e.target.value] = settings[setting];
                                    delete newSettings[setting];
                                    setSettings(newSettings);
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Value"
                                variant="outlined"
                                value={settings[setting]}
                                onChange={(e) =>
                                    setSettings({ ...settings, [setting]: e.target.value })
                                }
                                style={{ marginRight: 6, marginLeft: 6 }}
                            />
                            <Button
                                variant="outlined"
                                onClick={() => handleUpdateSettings(settings)}
                                color="secondary"
                                style={{ width: 100, marginRight: 6 }}
                            >
                                Ändra
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    const newSettings = { ...settings };
                                    delete newSettings[setting];
                                    handleUpdateSettings(newSettings);
                                }}
                                color="secondary"
                                style={{ width: 100 }}
                            >
                                Radera
                            </Button>
                        </div>
                    ))}
                <div style={{ display: 'flex', marginTop: 6 }}>
                    <TextField
                        fullWidth
                        label="Namn"
                        variant="outlined"
                        value={paramName}
                        onChange={(e) => setParamName(e.target.value)}
                    />
                    ¯
                    <TextField
                        fullWidth
                        label="Värde"
                        variant="outlined"
                        value={paramValue}
                        onChange={(e) => setParamValue(e.target.value)}
                        style={{ marginRight: 6, marginLeft: 6 }}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => handleNewSettings()}
                        style={{ width: 290 }}
                        disabled={!paramName || !paramValue || (settings && !!settings[paramName])}
                    >
                        Skapa ny
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
export default QuestionSettings;
