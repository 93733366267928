import { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as ExclamationSmall } from '../../../assets/svgs/ExclamationSmall.svg';
import styles from './FormAlert.style';

interface FormAlertProps {
    message: string;
    noMargin?: boolean;
}

const FormAlert: FC<FormAlertProps> = ({ message, noMargin }) => {
    const classes = styles();
    return (
        <div className={clsx(classes.errorMessageContainer, noMargin && classes.noMargin)}>
            <div>
                <ExclamationSmall className={classes.errorIcon} />
            </div>
            <p className={classes.errorMessage}>{message}</p>
        </div>
    );
};

export default FormAlert;
