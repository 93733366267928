import axios from 'axios';

import {
    changePasswordUrl,
    initiateResetUrl,
    loginUrl,
    logoutUrl,
    refreshTokenUrl,
} from '../endpoints/authentication';

export interface LoginPayload {
    email: string;
    password: string;
}

export interface InitiateResetPayload {
    email: string;
}

export interface ChangePasswordPayload {
    password: string;
    token: string;
}

interface TokenResponse {
    accessToken: string;
    refreshToken: string;
}

export const login = async (payload: LoginPayload) => {
    const { data } = await axios.post<TokenResponse>(loginUrl, payload);

    return data;
};

export const logout = async () => {
    return axios.post(logoutUrl);
};

export const initiateResetPassword = async (payload: InitiateResetPayload) => {
    return axios.post(initiateResetUrl, payload);
};

export const refreshAccessToken = async (currentRefreshToken: string) => {
    try {
        const result = await axios.get<TokenResponse>(refreshTokenUrl, {
            headers: {
                Authorization: `Bearer ${currentRefreshToken}`,
            },
        });
        const { accessToken, refreshToken } = result.data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        return { accessToken, refreshToken };
    } catch (e) {
        throw new Error('Refreshing access token failed');
    }
};

export const changePassword = async (requestBody: ChangePasswordPayload) => {
    await axios.put(changePasswordUrl, requestBody);
};
