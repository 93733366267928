import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useToastContext from '../../hooks/useToastContext';
import { ReactComponent as Offline } from '../../assets/svgs/Offline.svg';
import { TOAST_TYPE } from '../../constants';

const PING_RESOURCE = '/ping.txt';
const ONLINE_POLLING_INTERVAL_MS = 10000; // How often we check if the user is connected to the internet.
const PING_TIMEOUT_TIME_MS = 5000;
const TOGGLE_TOAST_DELAY = 1000;

// Setting up our fetch requests to timeout after a certain
//  amount of time to make sure we handle super slow networks.
const timeout = (time: number, promise: Promise<Response>) => {
    return new Promise(function (resolve, reject) {
        setTimeout(() => {
            reject(new Error('Request timed out.'));
        }, time);

        promise.then(resolve, reject);
    });
};

const ping = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
        await timeout(
            PING_TIMEOUT_TIME_MS,
            fetch(PING_RESOURCE, {
                method: 'GET',
                signal,
                cache: 'no-store',
            }),
        );

        return true;
    } catch (error) {
        // This can be because of request timed out
        // so we abort the request for any case
        controller.abort();

        return false;
    }
};

const NetworkStatus: FC = () => {
    const { setToast, toggleToast } = useToastContext();
    const { t } = useTranslation();

    const checkOnlineStatus = async () => {
        const online = await ping();

        if (online) {
            toggleToast(false, TOGGLE_TOAST_DELAY);
        }
    };

    const showOfflineToast = () => {
        setToast({
            message: t('toast-network-status-offline'),
            icon: <Offline />,
            fixed: true,
            type: TOAST_TYPE.NETWORKSTATUS,
        });
        toggleToast(true, TOGGLE_TOAST_DELAY);
    };

    useEffect(() => {
        window.addEventListener('offline', showOfflineToast);

        // Why aren't we using 'addEventListener('online')' ?
        // 1. The event listener 'online' is triggered when the user has access to any network (not necessarily to the internet).
        //    So we might have a false positive: The user is connected to some network but doesn't have access to the internet.
        // 2. It might happen that the user goes from 'offline' -> 'very slow connection' -> 'fast connection'.
        //    When the first transition happens ('offline' -> 'very slow connection'), the 'online' event is triggered but the
        //    internet is so slow that it couldn't fetch the ping.txt file. So when the second transition happens
        //    ('very slow connection' -> 'fast connection') the 'online' event isn't triggered again and the Toast doesn't disappear.
        //    That's why we keep checking for 'online' status.
        const id = setInterval(async () => {
            await checkOnlineStatus();
        }, ONLINE_POLLING_INTERVAL_MS);

        return () => {
            window.removeEventListener('offline', showOfflineToast);

            clearInterval(id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default NetworkStatus;
