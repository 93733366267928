import { createUseStyles } from 'react-jss';
import * as layout from '../../../foundation/layout';
import * as colors from '../../../foundation/colors';
import * as typography from '../../../foundation/typography';

const styles = createUseStyles({
    container: {
        marginBottom: `${layout.baseSpacing}px`,
    },
    inputContainer: {
        minHeight: '56px',
        border: `2px solid ${colors.neutral._200}`,
        borderRadius: '4px',
        padding: '6px 12px 8px',
        display: 'flex',
        flexDirection: 'column',
        background: colors.neutral.white,
    },
    activeInput: {
        borderColor: colors.active,
    },
    errorInput: {
        borderColor: colors.action,
    },
    label: {
        ...typography.footnote.bold,
        color: colors.black.mediumEmphasis,
    },
    labelFocused: {
        color: colors.black.highEmphasis,
    },
    input: {
        background: 'none',
        flex: 1,
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
        resize: 'none',
        ...typography.body._2.regular,
        color: colors.black.highEmphasis,
    },
    helperText: {
        ...typography.footnote.bold,
        color: colors.black.mediumEmphasis,
    },
    errorText: {
        color: colors.action,
    },
});

export default styles;
